import { Flex, Icon, Link, ListItem, Spinner, Tag, Text, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import React from "react"
import { XCircle } from "react-feather"
import { NavLink } from "react-router-dom"
import { AssetLocationRecordFragment, useZoneByIdQuery } from "../../graphql"
import { getDurationInWords, transformDateTime } from "../../utils"
import { Stat } from "../common"

export type AssetLocationListItemProps = {
	assetLocationRecord: AssetLocationRecordFragment
}

export const AssetLocationListItem: React.FC<AssetLocationListItemProps> = ({ assetLocationRecord }) => {
	const [{ data, fetching, error }] = useZoneByIdQuery({ variables: { zoneId: assetLocationRecord.zoneId } })

	return (
		<ListItem>
			<VStack w="full" align="flex-start" p={4}>
				{assetLocationRecord.isRestricted && (
					<Tag colorScheme="error" variant="solid">
						Restricted <Icon as={XCircle} ml="2" />
					</Tag>
				)}
				<Flex align="flex-start" flexWrap="wrap" gap={4}>
					<Stat
						label="Zone"
						value={
							fetching ? (
								<Spinner size="sm" />
							) : error ? (
								<Text color="error.500">{error.message.replace("[GraphQL] ", "")}</Text>
							) : !data?.zoneById ? (
								<Text color="error.500">No zone</Text>
							) : (
								<Link as={NavLink} to={`/zones/${assetLocationRecord.zoneId}`}>
									{data.zoneById.label.name}
								</Link>
							)
						}
					/>
					<Stat label="Since" value={transformDateTime(new Date(assetLocationRecord.startAt))} />
					<Stat label="Until" value={transformDateTime(new Date(assetLocationRecord.endAt))} />
					<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(assetLocationRecord.endAt), new Date(assetLocationRecord.startAt)))} />
				</Flex>
			</VStack>
		</ListItem>
	)
}
