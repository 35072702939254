import { Button, ButtonGroup, Fade, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import { PermissionTags, useMeQuery, UserFragment, UserRoleTypes } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, TitleHeading } from "../common"
import { VisitorCurrentDetails } from "./VisitorCurrentDetails"
import { VisitorPastDetails } from "./VisitorPastDetails"

export type VisitorDetailsProps = {
	user: UserFragment
}

export const VisitorDetails: React.FC<VisitorDetailsProps> = ({ user }) => {
	const [{ data }] = useMeQuery()

	const [selectedTab, setSelectedTab] = useState(0)

	const { checkForPermissions } = useUserPermissions()

	const canUpdate = useMemo(() => {
		if (selectedTab !== 0) return false

		if (user?._id === data?.me?._id) return true

		if (user?.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.UpdateWorkingUser)[0]
		}

		if (user?.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.UpdateShortTermUser)[0]
		}

		if (user?.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(PermissionTags.UpdateAdminUser)[0]
		}

		return false
	}, [JSON.stringify(user), selectedTab])

	return (
		<Tabs w="full" size="sm" variant="soft-rounded" colorScheme="primary" isLazy lazyBehavior="unmount" index={selectedTab} onChange={setSelectedTab}>
			<Card
				title={
					(
						<HStack>
							<TitleHeading title="Visitor Details" />
							{user.pastVisitorDetails?.length ? (
								<TabList w="fit-content" bgColor="primary.50" mx={2} p={1} rounded="2xl">
									<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
										Current
									</Tab>

									<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
										Past
									</Tab>
								</TabList>
							) : (
								<></>
							)}
						</HStack>
					) as any
				}
				alwaysShowAction
				action={
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/users/${user._id}/updateVisitorDetails`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				}
			>
				<TabPanels w="full">
					<TabPanel as={VStack} w="full" align="stretch" p="0">
						<Fade in>
							<VisitorCurrentDetails user={user} />
						</Fade>
					</TabPanel>
					{user.pastVisitorDetails?.length && (
						<TabPanel as={VStack} w="full" align="stretch" p="0">
							<Fade in>
								<VisitorPastDetails user={user} />
							</Fade>
						</TabPanel>
					)}
				</TabPanels>
			</Card>
		</Tabs>
	)
}
