import { Select } from "@chakra-ui/react"
import { camelCase, startCase } from "lodash"
import React from "react"
import { DashboardTypes } from "../../constants"
import { useDashboardContext } from "../../hooks"

export const DashboardTypeSelector: React.FC = () => {
	const { type: currentDashboardType, dispatch, setDashboardType, availableDashboardTypes } = useDashboardContext()

	return (
		<>
			{availableDashboardTypes.length > 1 && (
				<Select size="sm" variant="filled" rounded="md" value={currentDashboardType} fontWeight="semibold" onChange={(e) => dispatch(setDashboardType(e.target.value as DashboardTypes))}>
					{availableDashboardTypes.map((type) => (
						<option key={type} value={type}>
							{startCase(camelCase(type))}
						</option>
					))}
				</Select>
			)}
		</>
	)
}
