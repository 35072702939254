import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { TagFragment } from "../../graphql"
import { TagListItem } from "./TagListItem"

export type TagsListProps = {
	tags: TagFragment[]

	loadMore?: () => void
}

export const TagsList: React.FC<TagsListProps> = ({ tags, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{tags.map((tag, i, arr) => (
				<Fragment key={tag._id}>
					<TagListItem key={tag._id} tag={tag} />
					{i < arr.length - 1 && <Divider />}
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
