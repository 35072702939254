import { Divider, List } from "@chakra-ui/react"
import React, { Fragment } from "react"
import { TagRecordFragment } from "../../graphql"
import { TagRecordsOfReaderListItem } from "./TagRecordsOfReaderListItem"

export type TagRecordsOfReaderListProps = {
	records: TagRecordFragment[]
}

export const TagRecordsOfReaderList: React.FC<TagRecordsOfReaderListProps> = ({ records }) => {
	return (
		<List spacing={2}>
			{records.map((record, i, arr) => (
				<Fragment key={record.tagHexId + i.toString()}>
					<TagRecordsOfReaderListItem record={record} />
					{i < arr.length - 1 && <Divider />}
				</Fragment>
			))}
		</List>
	)
}
