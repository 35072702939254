/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightAddon, NumberInput, NumberInputField, Select, Switch, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import startCase from "lodash/startCase"
import React, { FormEvent, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { AlertTargetGroupSelector, AssetCategorySelector, AssetSelector, DepartmentsSelector, UserGroupsSelector, UserRoleTypesSelector, UsersSelector, UserTypesSelector, ZoneGroupsSelector, ZoneSelector } from "../components"
import { AlertFlowFragment, AlertSourceInput, AlertSourceTypes, AlertTypes, AssetTypes, UpdateAlertFlowMutationVariables, useMeQuery, UserRoleTypes, useUpdateAlertFlowMutation } from "../graphql"

type UpdateAlertFlowFormValues = UpdateAlertFlowMutationVariables["input"]

const alertTypeToAlertSourceTypesMap = new Map<AlertTypes, AlertSourceTypes[]>()
alertTypeToAlertSourceTypesMap.set(AlertTypes.InactiveService, [])
alertTypeToAlertSourceTypesMap.set(AlertTypes.TagBatteryLow, [])
alertTypeToAlertSourceTypesMap.set(AlertTypes.DailyStatisticsReport, [])
alertTypeToAlertSourceTypesMap.set(AlertTypes.Sos, [])
alertTypeToAlertSourceTypesMap.set(AlertTypes.InactiveReader, [AlertSourceTypes.Zones, AlertSourceTypes.ZoneGroups])
alertTypeToAlertSourceTypesMap.set(AlertTypes.RestrictedEntry, [AlertSourceTypes.Zones, AlertSourceTypes.ZoneGroups])
alertTypeToAlertSourceTypesMap.set(AlertTypes.UserRoleExpiry, [AlertSourceTypes.Users, AlertSourceTypes.UserGroups, AlertSourceTypes.UserTypes, AlertSourceTypes.UserRoleTypes, AlertSourceTypes.Departments])
alertTypeToAlertSourceTypesMap.set(AlertTypes.LowAssetInventory, [AlertSourceTypes.Assets, AlertSourceTypes.AssetCategories])
alertTypeToAlertSourceTypesMap.set(AlertTypes.UnauthorizedAssetMovement, [AlertSourceTypes.Assets, AlertSourceTypes.AssetCategories])
alertTypeToAlertSourceTypesMap.set(AlertTypes.AssetPlacementRequest, [AlertSourceTypes.Assets, AlertSourceTypes.AssetCategories])
alertTypeToAlertSourceTypesMap.set(AlertTypes.AssetPlacementFailure, [AlertSourceTypes.Assets, AlertSourceTypes.AssetCategories])
alertTypeToAlertSourceTypesMap.set(AlertTypes.AssetPlacementSuccess, [AlertSourceTypes.Assets, AlertSourceTypes.AssetCategories])

const validationSchema = yup.object<Record<keyof UpdateAlertFlowFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	type: yup.string().oneOf(Object.values(AlertTypes)).required().label("Type"),
	source: yup.object<Record<keyof UpdateAlertFlowFormValues["source"], yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
		type: yup.string().oneOf(Object.values(AlertSourceTypes)).nullable().label("Source Type"),
		zoneIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.Zones, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Zones"),
		zoneGroupIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.ZoneGroups, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Zone Groups"),
		userIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.Users, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Users"),
		userGroupIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.UserGroups, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("User Groups"),
		userTypeIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.UserTypes, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("User Types"),
		userRoleTypes: yup
			.array()
			.when("type", {
				is: AlertSourceTypes.UserRoleTypes,
				then: yup
					.array(yup.string().oneOf(Object.values(UserRoleTypes)))
					.required()
					.min(1),
			})
			.nullable()
			.label("User Role Types"),
		departmentIds: yup
			.array()
			.when("type", { is: AlertSourceTypes.Departments, then: yup.array(yup.string()).required().min(1) })
			.nullable()
			.label("Departments"),
	}),
	targetGroupIds: yup.array(yup.string()).required().min(1).label("Target Groups"),
	lowInventoryThreshold: yup
		.number()
		.when("type", { is: AlertTypes.LowAssetInventory, then: yup.number().required().min(0) })
		.nullable()
		.label("Low Inventory Threshold"),
	isResolutionRequired: yup.bool().nullable().label("Is Resolution Required"),
})

export type UpdateAlertFlowFormProps = {
	alertFlow: AlertFlowFragment
}

export const UpdateAlertFlowForm: React.FC<UpdateAlertFlowFormProps> = ({ alertFlow }) => {
	const initialValues: UpdateAlertFlowFormValues = {
		label: { name: alertFlow.label.name, description: alertFlow.label.description },
		type: alertFlow.type,
		source: {
			type: alertFlow.source?.type,
			zoneIds: alertFlow.source?.zoneIds,
			zoneGroupIds: alertFlow.source?.zoneGroupIds,
			userIds: alertFlow.source?.userIds,
			userGroupIds: alertFlow.source?.userGroupIds,
			userTypeIds: alertFlow.source?.userTypeIds,
			userRoleTypes: alertFlow.source?.userRoleTypes,
			departmentIds: alertFlow.source?.departmentIds,
			assetIds: alertFlow.source?.assetIds,
			assetCategoryIds: alertFlow.source?.assetCategoryIds,
		},
		targetGroupIds: alertFlow.targetGroupIds,
		lowInventoryThreshold: alertFlow.lowInventoryThreshold,
		isResolutionRequired: alertFlow.isResolutionRequired,
	}

	const [{ fetching }, updateAlertFlow] = useUpdateAlertFlowMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateAlertFlowFormValues) => {
		const { data, error } = await updateAlertFlow({
			alertFlowId: alertFlow._id,
			input: { ...values, source: [AlertTypes.InactiveService, AlertTypes.TagBatteryLow, AlertTypes.DailyStatisticsReport, AlertTypes.Sos].includes(values.type) ? undefined : values.source },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateAlertFlow) {
			navigate(`/alerts/flows/${data.updateAlertFlow._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateAlertFlowFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: meQueryData }] = useMeQuery()

	const alertTypes = useMemo(() => {
		const _ = Object.values(AlertTypes).filter((o) => o !== AlertTypes.Unknown)

		if (meQueryData?.me?.roleType !== UserRoleTypes.Admin) {
			return _.filter((o) => o !== AlertTypes.TagBatteryLow && o !== AlertTypes.InactiveService)
		}

		return _.filter((type) => type !== AlertTypes.Unknown)
	}, [meQueryData?.me])

	const sourceTypes = useMemo(() => {
		const availableAlertSourceTypes = alertTypeToAlertSourceTypesMap.get(formik.values.type as AlertTypes) ?? []

		if (!availableAlertSourceTypes.length) {
			formik.setFieldValue("source.type", undefined)
			return []
		}

		if (!formik.values.source?.type || !availableAlertSourceTypes.includes(formik.values.source.type)) {
			formik.setFieldValue("source.type", availableAlertSourceTypes[0])
		}

		return availableAlertSourceTypes
	}, [formik.values.type])

	const assetTypes = useMemo(
		() =>
			formik.values.type === AlertTypes.UnauthorizedAssetMovement ||
			formik.values.type === AlertTypes.AssetPlacementFailure ||
			formik.values.type === AlertTypes.AssetPlacementRequest ||
			formik.values.type === AlertTypes.AssetPlacementSuccess
				? [AssetTypes.Tagged]
				: formik.values.type === AlertTypes.LowAssetInventory
				? [AssetTypes.Weighed]
				: [AssetTypes.Tagged, AssetTypes.Weighed],
		[formik.values.type]
	)

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch" maxW="xl">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.errors.isResolutionRequired)}>
					<FormLabel fontWeight="bold">Is resolution required?</FormLabel>

					<Switch colorScheme="success" isChecked={!!formik.values.isResolutionRequired} onChange={(e) => formik.setFieldValue("isResolutionRequired", e.target.checked)} />

					<FormErrorMessage>{formik.errors.isResolutionRequired}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)} isRequired>
					<FormLabel fontWeight="bold">Type</FormLabel>

					<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("type")}>
						{alertTypes.map((type) => (
							<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
								{startCase(type)}
							</option>
						))}
					</Select>

					<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
				</FormControl>

				{formik.values.type === AlertTypes.LowAssetInventory && (
					<FormControl isInvalid={Boolean(formik.touched.lowInventoryThreshold && formik.errors.lowInventoryThreshold)} isRequired>
						<FormLabel fontWeight="bold">Threshold</FormLabel>

						<InputGroup>
							<NumberInput
								roundedLeft="xl"
								variant="filled"
								bgColor="grayscale.input-background"
								placeholder="Enter threshold"
								value={formik.values.lowInventoryThreshold || 0}
								onChange={(_, valueAsNumber) => formik.setFieldValue("lowInventoryThreshold", valueAsNumber)}
							>
								<NumberInputField />
							</NumberInput>
							<InputRightAddon>
								<Text>Units</Text>
							</InputRightAddon>
						</InputGroup>

						<FormErrorMessage>{formik.errors.lowInventoryThreshold}</FormErrorMessage>
					</FormControl>
				)}

				{sourceTypes.length ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.type && (formik.errors.source as AlertSourceInput)?.type)} isRequired>
						<FormLabel fontWeight="bold">Source Type</FormLabel>

						<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Source Type" {...formik.getFieldProps("source.type")}>
							{sourceTypes.map((type) => (
								<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
									{startCase(type)}
								</option>
							))}
						</Select>

						<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
					</FormControl>
				) : (
					<></>
				)}

				{formik.values.source?.type === AlertSourceTypes.Zones ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.zoneIds && (formik.errors.source as AlertSourceInput)?.zoneIds)}>
						<FormLabel fontWeight="bold">Source Zones</FormLabel>

						<ZoneSelector value={formik.values.source.zoneIds || []} onUpdate={(zoneIds) => formik.setFieldValue("source.zoneIds", zoneIds)} />

						<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.zoneIds}</FormErrorMessage>
					</FormControl>
				) : formik.values.source?.type === AlertSourceTypes.ZoneGroups ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.zoneGroupIds && (formik.errors.source as AlertSourceInput)?.zoneGroupIds)}>
						<FormLabel fontWeight="bold">Source Zone Groups</FormLabel>

						<ZoneGroupsSelector value={formik.values.source.zoneGroupIds || []} onUpdate={(zoneGroupIds) => formik.setFieldValue("source.zoneGroupIds", zoneGroupIds)} />

						<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.zoneGroupIds}</FormErrorMessage>
					</FormControl>
				) : formik.values.source?.type === AlertSourceTypes.Users ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.userIds && (formik.errors.source as AlertSourceInput)?.userIds)}>
						<FormLabel fontWeight="bold">Source Users</FormLabel>

						<UsersSelector value={formik.values.source.userIds || []} onUpdate={(userIds) => formik.setFieldValue("source.userIds", userIds)} />

						<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userIds}</FormErrorMessage>
					</FormControl>
				) : formik.values.source?.type === AlertSourceTypes.UserGroups ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.userGroupIds && (formik.errors.source as AlertSourceInput)?.userGroupIds)}>
						<FormLabel fontWeight="bold">Source User Groups</FormLabel>

						<UserGroupsSelector value={formik.values.source.userGroupIds || []} onUpdate={(userGroupIds) => formik.setFieldValue("source.userGroupIds", userGroupIds)} />

						<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userGroupIds}</FormErrorMessage>
					</FormControl>
				) : formik.values.source?.type === AlertSourceTypes.UserTypes ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.userTypeIds && (formik.errors.source as AlertSourceInput)?.userTypeIds)}>
						<FormLabel fontWeight="bold">Source User Types</FormLabel>

						<UserTypesSelector value={formik.values.source.userTypeIds || []} onUpdate={(userTypeIds) => formik.setFieldValue("source.userTypeIds", userTypeIds)} />

						<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userTypeIds}</FormErrorMessage>
					</FormControl>
				) : formik.values.source?.type === AlertSourceTypes.UserRoleTypes ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.userRoleTypes && (formik.errors.source as AlertSourceInput)?.userRoleTypes)}>
						<FormLabel fontWeight="bold">Source User Role Types</FormLabel>

						<UserRoleTypesSelector value={formik.values.source.userRoleTypes || []} onUpdate={(userRoleTypes) => formik.setFieldValue("source.userRoleTypes", userRoleTypes)} />

						<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.userRoleTypes}</FormErrorMessage>
					</FormControl>
				) : formik.values.source?.type === AlertSourceTypes.Departments ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.departmentIds && (formik.errors.source as AlertSourceInput)?.departmentIds)}>
						<FormLabel fontWeight="bold">Source Departments</FormLabel>

						<DepartmentsSelector value={formik.values.source.departmentIds || []} onUpdate={(departmentIds) => formik.setFieldValue("source.departmentIds", departmentIds)} />

						<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.departmentIds}</FormErrorMessage>
					</FormControl>
				) : formik.values.source?.type === AlertSourceTypes.Assets ? (
					<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.assetIds && (formik.errors.source as AlertSourceInput)?.assetIds)}>
						<FormLabel fontWeight="bold">Source Assets</FormLabel>

						<AssetSelector types={assetTypes} value={formik.values.source.assetIds || []} onUpdate={(assetIds) => formik.setFieldValue("source.assetIds", assetIds)} />

						<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.assetIds}</FormErrorMessage>
					</FormControl>
				) : (
					formik.values.source?.type === AlertSourceTypes.AssetCategories && (
						<FormControl isInvalid={Boolean((formik.touched.source as AlertSourceInput)?.assetCategoryIds && (formik.errors.source as AlertSourceInput)?.assetCategoryIds)}>
							<FormLabel fontWeight="bold">Source Assets</FormLabel>

							<AssetCategorySelector value={formik.values.source.assetCategoryIds || []} onUpdate={(assetCategoryIds) => formik.setFieldValue("source.assetCategoryIds", assetCategoryIds)} />

							<FormErrorMessage>{(formik.errors.source as AlertSourceInput)?.assetCategoryIds}</FormErrorMessage>
						</FormControl>
					)
				)}
				<FormControl isInvalid={Boolean(formik.touched.targetGroupIds && formik.errors.targetGroupIds)}>
					<FormLabel fontWeight="bold">Alert Target Groups</FormLabel>

					<AlertTargetGroupSelector value={formik.values.targetGroupIds || []} onUpdate={(targetGroupIds) => formik.setFieldValue("targetGroupIds", targetGroupIds)} />

					<FormErrorMessage>{formik.errors.targetGroupIds}</FormErrorMessage>
				</FormControl>
				<Button type="submit" colorScheme="primary" isLoading={fetching}>
					Update
				</Button>
			</VStack>
		</VStack>
	)
}
