import { VStack } from "@chakra-ui/react"
import React from "react"
import { ExtendUserRoleForm } from "../../forms"
import { UserRoleFragment } from "../../graphql"

export type ExtendUserRoleProps = {
	userRole: UserRoleFragment
}

export const ExtendUserRole: React.FC<ExtendUserRoleProps> = ({ userRole }) => {
	return (
		<VStack w="full" maxW="sm" spacing={6}>
			<ExtendUserRoleForm userRole={userRole} />
		</VStack>
	)
}
