import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react"
import React from "react"
import { AssignTagToAssetByDecimalIdForm, AssignTagToAssetForm } from "../../forms"
import { AssetFragment } from "../../graphql"

export type AssignTagToAssetProps = {
	asset: AssetFragment
}

export const AssignTagToAsset: React.FC<AssignTagToAssetProps> = ({ asset }) => {
	return (
		<VStack w="full" align="stretch" spacing={6}>
			<Tabs flex={1} size="sm" variant="soft-rounded" colorScheme="primary" isLazy lazyBehavior="unmount">
				<TabList w="fit-content" bgColor="primary.50" p={1} rounded="2xl">
					<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
						By Device
					</Tab>
					<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
						By Tag Id
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel as={VStack} w="full" align="stretch" px="0">
						<AssignTagToAssetForm asset={asset} />
					</TabPanel>
					<TabPanel as={VStack} w="full" align="stretch" px="0">
						<AssignTagToAssetByDecimalIdForm asset={asset} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</VStack>
	)
}
