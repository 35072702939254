import { Flex, Link, LinkBox, LinkOverlay, ListItem, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import { startCase } from "lodash"
import React from "react"
import { NavLink } from "react-router-dom"
import { AssetFragment, AssetStatus, Features } from "../../graphql"
import { useFeatures } from "../../hooks"
import { AssetTag, Stat } from "../common"

export type AssetListItemProps = {
	asset: AssetFragment
	onAssetClick?: (asset: AssetFragment) => void
}

export const AssetListItem: React.FC<AssetListItemProps> = ({ asset, onAssetClick }) => {
	const { isFeatureEnabled } = useFeatures()

	return (
		<ListItem as={onAssetClick ? "li" : LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer" onClick={() => (onAssetClick ? onAssetClick(asset) : undefined)}>
			{!onAssetClick && <LinkOverlay as={NavLink} to={`/assets/${asset._id}`} />}

			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat
					label="Asset"
					value={
						<Link as={NavLink} to={`/assets/${asset._id}`}>
							<AssetTag asset={asset} />
						</Link>
					}
				/>
				{isFeatureEnabled(Features.WeighedAssets) && <Stat label="Type" value={startCase(asset.type)} />}{" "}
				<Stat label="Status" value={<Text color={asset.status === AssetStatus.Active ? "success.500" : "error.500"}>{asset.status}</Text>} />
				<Stat label="Last updated at" value={format(new Date(asset.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(asset.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
