import { Flex, Link, ListItem, Spinner, Text, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { UserLocationRecordFragment, useUserByIdQuery, useZoneByIdQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Stat, UserTag } from "../common"

export type RestrictedEntryListItemProps = {
	userLocationRecord: UserLocationRecordFragment
}

export const RestrictedEntryListItem: React.FC<RestrictedEntryListItemProps> = ({ userLocationRecord }) => {
	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({ variables: { zoneId: userLocationRecord.zoneId } })
	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: userLocationRecord.userId } })

	return (
		<ListItem>
			<VStack w="full" align="stretch" bgColor="red.100" rounded="xl">
				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					<Stat
						label="User"
						value={
							fetchingUser ? (
								<Spinner size="sm" />
							) : userError ? (
								<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
							) : !userData?.userById ? (
								<Text color="error.500">No user</Text>
							) : (
								<Link as={NavLink} to={`/users/${userLocationRecord.userId}`}>
									<UserTag user={userData.userById} />
								</Link>
							)
						}
					/>
					<Stat
						label="Zone"
						value={
							fetchingZone ? (
								<Spinner size="sm" />
							) : zoneError ? (
								<Text color="error.500">{zoneError.message.replace("[GraphQL] ", "")}</Text>
							) : !zoneData?.zoneById ? (
								<Text color="error.500">No zone</Text>
							) : (
								<Link as={NavLink} to={`/zones/${userLocationRecord.zoneId}`}>
									{zoneData.zoneById.label.name}
								</Link>
							)
						}
					/>
					<Stat label="Since" value={format(new Date(userLocationRecord.startAt), "MMM dd, yyyy p")} />
					<Stat label="Until" value={format(new Date(userLocationRecord.endAt), "MMM dd, yyyy p")} />
					<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(userLocationRecord.endAt), new Date(userLocationRecord.startAt)))} />
				</Flex>
			</VStack>
		</ListItem>
	)
}
