import { StackProps, Text, VStack } from "@chakra-ui/react"
import React from "react"

export type StatProps = {
	label: string | number | React.ReactNode
	value: string | number | React.ReactNode
} & StackProps

export const Stat: React.FC<StatProps> = ({ label, value, ...props }) => {
	return (
		<VStack alignItems="flex-start" spacing={0} w={28} {...props}>
			{typeof label === "string" || typeof label === "number" ? (
				<Text fontSize="xs" color="grayscale.label" wordBreak="break-word">
					{label}
				</Text>
			) : (
				<VStack w="full" alignItems="flex-start" fontSize="xs" color="grayscale.label" wordBreak="break-word">
					{label}
				</VStack>
			)}
			{typeof value === "string" || typeof value === "number" ? (
				<Text fontSize="sm" fontWeight="bold" color="grayscale.body" wordBreak="break-word">
					{value}
				</Text>
			) : (
				<VStack w="full" alignItems="flex-start" fontSize="sm" fontWeight="bold" color="grayscale.body" wordBreak="break-word">
					{value}
				</VStack>
			)}
		</VStack>
	)
}
