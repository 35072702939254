import { Center, Heading, HStack, Icon, Link, Spinner, Tag, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { TrendingDown, TrendingUp } from "react-feather"
import { NavLink } from "react-router-dom"
import { useActiveAssetIdsInLastIntervalQuery, useActiveAssetIdsQuery } from "../../graphql"
import { useIntervalContext } from "../../hooks"
import { useQueryRefresher } from "../../hooks/useQueryRefresher"

export const CurrentlyActiveAssets: React.FC = () => {
	const [interval] = useIntervalContext()

	const [{ data, fetching, error }, refreshActiveAssetIdsQuery] = useActiveAssetIdsQuery()
	const [{ data: pastData }, refreshActiveAssetIdsInLastIntervalQuery] = useActiveAssetIdsInLastIntervalQuery({ variables: { intervalInMinutes: interval } })

	useQueryRefresher(() => refreshActiveAssetIdsQuery({ requestPolicy: "network-only" }))
	useQueryRefresher(() => refreshActiveAssetIdsInLastIntervalQuery({ requestPolicy: "network-only" }))

	const dodPercent = useMemo(() => {
		const CURRENT = data?.activeAssetIds.assetIds.length || 0
		const PREV = pastData?.activeAssetIdsInLastInterval.length || 0

		const percent = (CURRENT / PREV) * 100 - 100

		return percent === Infinity ? 0 : percent
	}, [data, pastData])

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">Currently active assets</Heading>
			{!data?.activeAssetIds && fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeAssetIds ? (
				<HStack>
					<Link as={NavLink} to="/assets/active">
						<Heading color="primary.400">{data.activeAssetIds.assetIds.length || 0}</Heading>
					</Link>

					{!!dodPercent && (
						<Tag px="4" rounded="full" colorScheme={dodPercent < 0 ? "red" : "green"}>
							{Math.abs(Math.round(dodPercent))}% <Icon as={dodPercent < 0 ? TrendingDown : TrendingUp} ml="2" />
						</Tag>
					)}
				</HStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
