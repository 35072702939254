import { Box, Flex, Icon, IconButton, Link, LinkBox, LinkOverlay, ListItem, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import { startCase } from "lodash"
import React, { useState } from "react"
import { X } from "react-feather"
import { NavLink } from "react-router-dom"
import { ReaderFragment, ReaderModes, useZoneByIdQuery } from "../../graphql"
import { Stat } from "../common"

export type ReaderListItemProps = {
	reader: ReaderFragment
	zoneId?: string
}

export const ReaderListItem: React.FC<ReaderListItemProps> = ({ reader, zoneId }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isHovered, setIsHovered] = useState(false)

	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({ variables: { zoneId: reader.assignedToZoneId ?? "" }, pause: !reader.assignedToZoneId })

	return (
		<Box position="relative">
			<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				<LinkOverlay as={NavLink} to={`/readers/${reader._id}`} />

				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					<Stat label="Name" value={reader.label.name} />
					<Stat label="UID" value={reader.readerDecimalId} />
					<Stat label="UID (HEX)" value={reader.readerHexId} />
					<Stat label="Type" value={startCase(reader.type)} />
					<Stat label="Mode" value={startCase(reader.mode)} />
					{reader.mode === ReaderModes.ZoneTracking && (
						<Stat
							label="Zone"
							value={
								reader.assignedToZoneId ? (
									fetchingZone ? (
										<Spinner size="sm" />
									) : zoneError ? (
										<Text color="error.500">{zoneError.message.replace("[GraphQL] ", "")}</Text>
									) : !zoneData?.zoneById ? (
										<Text color="error.500">No zone</Text>
									) : (
										<Link as={NavLink} to={`/zones/${reader.assignedToZoneId}`}>
											{zoneData.zoneById.label.name}
										</Link>
									)
								) : (
									"Not assigned"
								)
							}
						/>
					)}
					<Stat label="Status" value={reader.isActive ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
					<Stat label="Last heartbeat at" value={reader.lastHeartbeatAt ? format(new Date(reader.lastHeartbeatAt), "MMM dd, yyyy p") : "Not available"} />
					<Stat label="Last connected at" value={reader.lastConnectedAt ? format(new Date(reader.lastConnectedAt), "MMM dd, yyyy p") : "Not available"} />
					<Stat label="Last updated at" value={format(new Date(reader.updatedAt), "MMM dd, yyyy p")} />
					<Stat label="Created at" value={format(new Date(reader.createdAt), "MMM dd, yyyy p")} />
				</Flex>
				{zoneId && isHovered && (
					<Box position="absolute" top={4} right={4}>
						<IconButton aria-label="remove-reader-from-zone-btn" as={NavLink} to={`/zones/${zoneId}/${reader._id}/remove`} colorScheme="error" size="sm">
							<Icon as={X} />
						</IconButton>
					</Box>
				)}
			</ListItem>
		</Box>
	)
}
