/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import DatePicker from "react-date-picker"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { UserRoleFragment, ExtendUserRoleMutationVariables, useExtendUserRoleMutation } from "../graphql"

type ExtendUserRoleFormValues = Pick<ExtendUserRoleMutationVariables, "endAt">

const validationSchema = yup.object<Record<keyof ExtendUserRoleFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	endAt: yup.date().required().label("End"),
})

export type ExtendUserRoleFormProps = {
	userRole: UserRoleFragment
}

export const ExtendUserRoleForm: React.FC<ExtendUserRoleFormProps> = ({ userRole }) => {
	const initialValues: ExtendUserRoleFormValues = {
		endAt: userRole.endAt ? new Date(userRole.endAt) : new Date(),
	}

	const [{ fetching }, extendUserRole] = useExtendUserRoleMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ endAt }: ExtendUserRoleFormValues) => {
		const { data, error } = await extendUserRole({ userId: userRole.userId, endAt })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.extendUserRole) {
			navigate(`/users/${userRole.userId}`, { replace: true })

			return
		}
	}

	const formik = useFormik<ExtendUserRoleFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.endAt && formik.errors.endAt)}>
					<FormLabel fontWeight="bold">End</FormLabel>

					<DatePicker onChange={(date) => formik.setFieldValue("endAt", date)} value={formik.values.endAt} format="MMM dd, y" minDate={new Date()} clearIcon={null} />

					<FormErrorMessage>{formik.errors.endAt as string}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Extend
			</Button>
		</VStack>
	)
}
