import { Flex, HStack, Link, ListItem, Spinner, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import { startCase } from "lodash"
import React from "react"
import { NavLink } from "react-router-dom"
import { useDepartmentByIdQuery, UserRoleFragment, UserRoleStatus, useUserByIdQuery, useUserRoleGroupByIdQuery } from "../../graphql"
import { Stat, UserTag } from "../common"

export type UserRoleListItemProps = {
	userRole: UserRoleFragment
	includeUser?: boolean
}

export const UserRoleListItem: React.FC<UserRoleListItemProps> = ({ userRole, includeUser }) => {
	const [{ data: userRoleGroupData, fetching: fetchingUserRoleGroup, error: userRoleGroupError }] = useUserRoleGroupByIdQuery({ variables: { userRoleGroupId: userRole.userRoleGroupId } })
	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: userRole.userId }, pause: !includeUser })
	const [{ data: departmentData, fetching: fetchingDepartment, error: departmentError }] = useDepartmentByIdQuery({
		variables: { departmentId: userRoleGroupData?.userRoleGroupById.departmentId || "" },
		pause: !userRoleGroupData?.userRoleGroupById.departmentId,
	})

	return (
		<ListItem rounded="xl">
			<HStack justifyContent="space-between" p="2">
				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					{includeUser ? (
						<Stat
							label="User"
							value={
								fetchingUser ? (
									<Spinner size="sm" />
								) : userError ? (
									<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
								) : !userData?.userById ? (
									<Text color="error.500">No user</Text>
								) : (
									<Link as={NavLink} to={`/users/${userRole.userId}`}>
										<UserTag user={userData.userById} />
									</Link>
								)
							}
						/>
					) : (
						<></>
					)}
					<Stat label="Role Type" value={startCase(userRole.type)} />

					<Stat
						label="Role Group"
						value={
							fetchingUserRoleGroup ? (
								<Spinner size="sm" />
							) : userRoleGroupError ? (
								<Text color="error.500">{userRoleGroupError.message.replace("[GraphQL] ", "")}</Text>
							) : !userRoleGroupData?.userRoleGroupById ? (
								<Text color="error.500">No role group</Text>
							) : (
								<Link as={NavLink} to={`/userRoleGroups/${userRole.userRoleGroupId}`}>
									<Text>{userRoleGroupData.userRoleGroupById.label.name}</Text>
								</Link>
							)
						}
					/>
					<Stat
						label="Department"
						value={
							fetchingDepartment ? (
								<Spinner size="sm" />
							) : departmentError ? (
								<Text color="error.500">{departmentError.message.replace("[GraphQL] ", "")}</Text>
							) : !departmentData?.departmentById ? (
								<Text color="error.500">No department</Text>
							) : (
								<Link as={NavLink} to={`/departments/${departmentData.departmentById._id}`}>
									<Text>{departmentData.departmentById.label.name}</Text>
								</Link>
							)
						}
					/>
					<Stat label="Designation" value={userRole.designation || "Not available"} />
					<Stat label="Start" value={format(new Date(userRole.startAt), "MMM dd, yyyy p")} />
					<Stat label="End" value={userRole.endAt ? format(new Date(userRole.endAt), "MMM dd, yyyy p") : "Not available"} />
					<Stat label="Assigned Since" value={format(new Date(userRole!.assignedAt), "MMM dd, yyyy p")} />
					<Stat
						label="Status"
						value={
							userRole.status === UserRoleStatus.Current ? (
								<Text color="success.600">Current</Text>
							) : userRole.status === UserRoleStatus.Expired ? (
								<Text color="error.600">Expired</Text>
							) : userRole.status === UserRoleStatus.Future ? (
								<Text color="warning.600">Future</Text>
							) : (
								<Text color="warning.600">Inactive</Text>
							)
						}
					/>
				</Flex>
			</HStack>
		</ListItem>
	)
}
