import { Center, Heading, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { useTodaySosRecordIdsQuery } from "../../graphql"
import { useQueryRefresher } from "../../hooks/useQueryRefresher"

export const TodaySosRecords: React.FC = () => {
	const [{ data, fetching, error }, refresh] = useTodaySosRecordIdsQuery()

	useQueryRefresher(() => refresh({ requestPolicy: "network-only" }))

	return (
		<VStack flex="1" maxW="52" align="stretch" spacing={4}>
			<Heading fontSize="sm">Today&apos;s SOS</Heading>
			{!data?.todaySosRecordIds && fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.todaySosRecordIds ? (
				<VStack align="stretch">
					<VStack align="stretch">
						<Link as={NavLink} to="/sosRecords/today">
							<Heading color="red.400">{data?.todaySosRecordIds.length || 0}</Heading>
						</Link>
						<Text fontSize="sm">{format(new Date(), "MMM dd, yyyy")}</Text>
					</VStack>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
