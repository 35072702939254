import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { PermissionsGroupFragment } from "../../graphql"
import { Stat } from "../common"

export type PermissionsGroupListItemProps = {
	permissionsGroup: PermissionsGroupFragment
}

export const PermissionsGroupListItem: React.FC<PermissionsGroupListItemProps> = ({ permissionsGroup }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/permissionsGroups/${permissionsGroup._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={permissionsGroup.label.name} />
				<Stat label="Permissions" value={permissionsGroup.tags.length || 0} />
				<Stat label="Last updated at" value={format(new Date(permissionsGroup.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(permissionsGroup.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
