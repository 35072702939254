import { Flex, Link, LinkBox, LinkOverlay, ListItem, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { UserFragment, UserStatus, useUserTypeByIdQuery } from "../../graphql"
import { Stat, UserTag } from "../common"

export type UserListItemProps = {
	user: UserFragment
	onUserClick?: (user: UserFragment) => void
}

export const UserListItem: React.FC<UserListItemProps> = ({ user, onUserClick }) => {
	const [{ data, fetching, error }] = useUserTypeByIdQuery({ variables: { userTypeId: user.typeId } })

	return (
		<ListItem as={onUserClick ? "li" : LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer" onClick={() => (onUserClick ? onUserClick(user) : undefined)}>
			{!onUserClick && <LinkOverlay as={NavLink} to={`/users/${user._id}`} />}

			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat
					label="User"
					value={
						<Link as={NavLink} to={`/users/${user._id}`}>
							<UserTag user={user} />
						</Link>
					}
				/>
				<Stat
					label="Type"
					value={
						fetching ? (
							<Spinner size="sm" />
						) : error ? (
							<Text color="error.500">{error.message.replace("[GraphQL] ", "")}</Text>
						) : !data?.userTypeById ? (
							<Text color="error.500">No user type</Text>
						) : (
							<Link as={NavLink} to={`/users/types/${user.typeId}`}>
								<Text fontSize="sm">{data.userTypeById.label.name}</Text>
							</Link>
						)
					}
				/>
				<Stat label="Status" value={<Text color={user.status === UserStatus.Active ? "success.500" : "error.500"}>{user.status}</Text>} />
				<Stat label="Last updated at" value={format(new Date(user.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(user.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
