import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { UserGroupFragment } from "../../graphql"
import { Stat } from "../common"

export type UserGroupListItemProps = {
	userGroup: UserGroupFragment
}

export const UserGroupListItem: React.FC<UserGroupListItemProps> = ({ userGroup }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/users/groups/${userGroup._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={userGroup.label.name} />
				<Stat label="Users" value={userGroup.userIds.length || 0} />
				<Stat label="Last updated at" value={format(new Date(userGroup.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(userGroup.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
