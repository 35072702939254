import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { TileFragment } from "../../graphql"
import { Stat } from "../common"

export type TileListItemProps = {
	tile: TileFragment
}

export const TileListItem: React.FC<TileListItemProps> = ({ tile }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/tiles/${tile._id}`} />

			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={tile.label.name} />
				<Stat label="Levels" value={tile.levelWiseZoneEmbeddings.length} />
				<Stat label="Embedded Zones" value={tile.levelWiseZoneEmbeddings.map((o) => o.zoneEmbeddings.length).reduce((acc, value) => acc + value, 0)} />
				<Stat label="Last updated at" value={format(new Date(tile.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(tile.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
