import { Flex, Link, Spinner, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { TagFragment, useAssetByIdQuery, useUserByIdQuery } from "../../graphql"
import { Card, Stat, UserTag } from "../common"

export type TagDetailsProps = {
	tag: TagFragment
}

export const TagDetails: React.FC<TagDetailsProps> = ({ tag }) => {
	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: tag.assignedToUserId ?? "" }, pause: !tag.assignedToUserId })
	const [{ data: assetData, fetching: fetchingAsset, error: assetError }] = useAssetByIdQuery({ variables: { assetId: tag.assignedToAssetId ?? "" }, pause: !tag.assignedToAssetId })

	return (
		<Card title="Details">
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				{tag.assignedToUserId && (
					<Stat
						label="User"
						value={
							fetchingUser ? (
								<Spinner size="sm" />
							) : userError ? (
								<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
							) : !userData?.userById ? (
								<Text color="error.500">No user</Text>
							) : (
								<Link as={NavLink} to={`/users/${userData.userById._id}`}>
									<UserTag user={userData.userById} />
								</Link>
							)
						}
					/>
				)}
				{tag.assignedToAssetId && (
					<Stat
						label="Asset"
						value={
							fetchingAsset ? (
								<Spinner size="sm" />
							) : assetError ? (
								<Text color="error.500">{assetError.message.replace("[GraphQL] ", "")}</Text>
							) : !assetData?.assetById ? (
								<Text color="error.500">No asset</Text>
							) : (
								<Link as={NavLink} to={`/assets/${tag.assignedToAssetId}`}>
									{assetData.assetById.name}
								</Link>
							)
						}
					/>
				)}
				<Stat label="UID" value={tag.tagDecimalId} />
				<Stat label="UID (HEX)" value={tag.tagHexId} />
				<Stat label="Status" value={tag.status} />
				<Stat label="Battery Status" value={tag.batteryStatus} />
				<Stat label="Last updated at" value={format(new Date(tag.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(tag.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
