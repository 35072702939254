import { Heading, HStack, StackProps, VStack } from "@chakra-ui/react"
import React, { PropsWithChildren, useState } from "react"

export const TitleHeading = ({ title }: { title: string }) => (
	<Heading fontSize="sm" color="grayscale.title">
		{title}
	</Heading>
)

export type CardProps = {
	title: string | React.ReactNode
	action?: React.ReactNode
	alwaysShowAction?: boolean
}

export const Card: React.FC<CardProps & PropsWithChildren & StackProps> = ({ title, children, action, alwaysShowAction, ...props }) => {
	const [isHovered, setIsHovered] = useState(false)

	return (
		<VStack w="full" align="stretch" {...props} rounded="xl" bgColor="grayscale.off-white" spacing={0} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			<HStack w="full" bgColor="grayscale.background" p="4" maxH="12" roundedTop="xl" justify="space-between">
				{typeof title === "string" ? <TitleHeading title={title} /> : title}
				{(isHovered || alwaysShowAction) && action}
			</HStack>
			{children}
		</VStack>
	)
}
