import { Center, Divider, List, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useNotifiedUsersOfAlertQuery } from "../../graphql"
import { Card } from "../common"
import { UserListItem } from "../users/UserListItem"

export type NotifiedUsersProps = {
	alertId: string
}

export const NotifiedUsers: React.FC<NotifiedUsersProps> = ({ alertId }) => {
	const [{ data, fetching, error }] = useNotifiedUsersOfAlertQuery({ variables: { alertId } })

	return (
		<Card title="Notified Users">
			{fetching && !data?.notifiedUsersOfAlert ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading users</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.notifiedUsersOfAlert.length ? (
				<List spacing={2}>
					{data.notifiedUsersOfAlert.map((user, i, arr) => (
						<>
							<UserListItem key={user._id} user={user} />
							{i < arr.length - 1 && <Divider />}
						</>
					))}
				</List>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any users.
					</Text>
				</Center>
			)}
		</Card>
	)
}
