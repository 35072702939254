import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { AlertFlowFragment } from "../../graphql"
import { AlertFlowListItem } from "./AlertFlowListItem"

export type AlertFlowsListProps = {
	alertFlows: AlertFlowFragment[]
	loadMore: () => void
}

export const AlertFlowsList: React.FC<AlertFlowsListProps> = ({ alertFlows, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{alertFlows.map((alertFlow, i, arr) => (
				<Fragment key={alertFlow._id}>
					<AlertFlowListItem key={alertFlow._id} alertFlow={alertFlow} />
					{i < arr.length - 1 && <Divider />}
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
