import { Button, Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { AlertTargetGroupFragment, PermissionTags } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type AlertTargetGroupDetailsProps = {
	alertTargetGroup: AlertTargetGroupFragment
}

export const AlertTargetGroupDetails: React.FC<AlertTargetGroupDetailsProps> = ({ alertTargetGroup }) => {
	const { checkForPermissions } = useUserPermissions()

	const canUpdate = useMemo(() => checkForPermissions(PermissionTags.UpdateAlertTargetGroup)[0], [])

	return (
		<Card
			title="Details"
			action={
				canUpdate && (
					<Button as={NavLink} to={`/alerts/targetGroups/${alertTargetGroup._id}/update`} colorScheme="primary" size="sm">
						Update
					</Button>
				)
			}
		>
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Targets" value={alertTargetGroup.userIds.length || 0} />
				<Stat label="Last updated at" value={format(new Date(alertTargetGroup.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(alertTargetGroup.createdAt), "MMM dd, yyyy p")} />{" "}
			</Flex>
		</Card>
	)
}
