import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { AssetCategoryFragment } from "../../graphql"
import { AssetCategoryListItem } from "./AssetCategoryListItem"

export type AssetCategoriesListProps = {
	assetCategories: AssetCategoryFragment[]
	loadMore?: () => void
}

export const AssetCategoriesList: React.FC<AssetCategoriesListProps> = ({ assetCategories, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{assetCategories.map((assetCategory, i, arr) => (
				<>
					<AssetCategoryListItem key={assetCategory._id} assetCategory={assetCategory} />
					{i < arr.length - 1 && <Divider />}
				</>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
