import { Button, Center, Flex, HStack, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { PermissionTags, UserFragment, UserRoleTypes, useUserPermissionsGroupsQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type UserPermissionsGroupsProps = {
	user: UserFragment
}

export const UserPermissionsGroups: React.FC<UserPermissionsGroupsProps> = ({ user }) => {
	const [{ data, fetching, error }] = useUserPermissionsGroupsQuery({ variables: { userId: user._id } })

	const { checkForPermissions } = useUserPermissions()

	const canUpdateUserPermissionsGroups = useMemo(() => {
		if (user.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(PermissionTags.UpdateAdminUserPermissionsGroup)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.UpdateShortTermUserPermissionsGroup)[0]
		}

		return checkForPermissions(PermissionTags.UpdateWorkingUserPermissionsGroup)[0]
	}, [])

	return (
		<Card
			title="Permissions Groups"
			action={
				canUpdateUserPermissionsGroups && (
					<Button as={NavLink} to={`/users/${user._id}/updatePermissionsGroups`} colorScheme="primary" size="sm">
						Update
					</Button>
				)
			}
		>
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading permissions groups</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userPermissionsGroups ? (
				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					<Stat
						label="Groups"
						value={
							data.userPermissionsGroups.length ? (
								<HStack flexWrap="wrap" justify="center" maxW="xs">
									{data.userPermissionsGroups.map((g, i) => (
										<Link key={g._id} as={NavLink} to={`/permissionsGroups/${g._id}`}>
											{g.label.name}
											{i < (data.userPermissionsGroups?.length || 0) - 1 && ","}
										</Link>
									))}
								</HStack>
							) : (
								"Not available"
							)
						}
					/>
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find permissions groups.
					</Text>
				</Center>
			)}
		</Card>
	)
}
