/* eslint-disable @typescript-eslint/indent */
import { Button, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { BuildingFragment, PermissionTags } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { BuildingFloorItem } from "./BuildingFloorItem"

export type BuildingFloorWiseUsersProps = {
	building: BuildingFragment
	disableActions?: boolean
}

export const BuildingFloorWiseUsers: React.FC<BuildingFloorWiseUsersProps> = ({ building, disableActions }) => {
	const { checkForSomePermissions } = useUserPermissions()

	const canAddFloor = useMemo(() => checkForSomePermissions(PermissionTags.CreateBuildingFloor)[0], [])

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<HStack w="full" justify="space-between" maxW="xl">
				<Heading fontSize="sm">All floors</Heading>
				{!disableActions && canAddFloor && (
					<Button as={NavLink} to={`/zones/buildings/${building._id}/addFloor`} colorScheme="primary" size="sm">
						Add
					</Button>
				)}
			</HStack>
			{building.floors.length ? (
				<Flex w="full" flexWrap="wrap">
					{building.floors.map((floor) => (
						<BuildingFloorItem key={floor._id} floor={floor} building={building} />
					))}
				</Flex>
			) : (
				<Text fontSize="sm" color="grayscale.label">
					No floors added
				</Text>
			)}
		</VStack>
	)
}
