import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { BuildingFragment } from "../../graphql"
import { BuildingListItem } from "./BuildingListItem"

export type BuildingsListProps = {
	buildings: BuildingFragment[]
	loadMore: () => void
}

export const BuildingsList: React.FC<BuildingsListProps> = ({ buildings, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{buildings.map((building, i, arr) => (
				<Fragment key={building._id}>
					<BuildingListItem key={building._id} building={building} />
					{i < arr.length - 1 && <Divider />}
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
