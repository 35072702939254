/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateReaderMutationVariables, ReaderModes, ReaderTypes, useCreateReaderMutation } from "../graphql"
import startCase from "lodash/startCase"

type CreateReaderFormValues = CreateReaderMutationVariables["input"]

const validationSchema = yup.object<Record<keyof CreateReaderFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().nullable().label("Description"),
	}),
	readerDecimalId: yup.string().required().label("Reader ID"),
	type: yup.string().oneOf(Object.values(ReaderTypes)).required().label("Reader Type"),
	mode: yup.string().oneOf(Object.values(ReaderModes)).required().label("Reader Mode"),
})

export const CreateReaderForm: React.FC = () => {
	const initialValues: CreateReaderFormValues = { label: { name: "", description: "" }, readerDecimalId: "", type: ReaderTypes.Indoor, mode: ReaderModes.ZoneTracking }

	const [{ fetching }, createReader] = useCreateReaderMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateReaderFormValues) => {
		const { data, error } = await createReader({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createReader) {
			navigate(`/readers/${data.createReader._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateReaderFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.readerDecimalId && formik.errors.readerDecimalId)}>
					<FormLabel fontWeight="bold">Reader ID</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter reader ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("readerDecimalId")} />

					<FormErrorMessage>{formik.errors.readerDecimalId}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.type && formik.errors.type)} isRequired>
					<FormLabel fontWeight="bold">Type</FormLabel>

					<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("type")}>
						{Object.values(ReaderTypes)
							.filter((o) => o !== ReaderTypes.Unknown)
							.map((type) => (
								<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
									{startCase(type)}
								</option>
							))}
					</Select>

					<FormErrorMessage>{formik.errors.type}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.mode && formik.errors.mode)} isRequired>
					<FormLabel fontWeight="bold">Mode</FormLabel>

					<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Mode" {...formik.getFieldProps("mode")}>
						{Object.values(ReaderModes)
							.filter((o) => o !== ReaderModes.Unknown)
							.map((mode) => (
								<option key={mode} style={{ backgroundColor: "transparent" }} value={mode}>
									{startCase(mode)}
								</option>
							))}
					</Select>

					<FormErrorMessage>{formik.errors.mode}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
