import { Button, Heading, HStack, Icon, useToast, VStack } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React, { useEffect, useRef, useState } from "react"
import { AlertCircle, CheckCircle } from "react-feather"
import { stripHtml } from "string-strip-html"
import { CheckEmailVerification, RestrictedEntriesModal } from "./components"
import { SosModal } from "./components/sosRecords"
import { ContextProvider } from "./context"
import { AlertTypes, useMeQuery, useNewAlertSubscription, useSessionQuery, useUserAuthSessionUpdateSubscription } from "./graphql"
import { usePermissionsContext } from "./hooks"
import { SplashPage } from "./pages/Splash.page"
import { RootRouter } from "./router/Root.router"
import { UnauthorizedRouter } from "./router/Unauthorized.router"

export const App: React.FC = () => {
	const [{ data, fetching }] = useMeQuery()
	const [{ data: sessionData }] = useSessionQuery()

	useUserAuthSessionUpdateSubscription({ variables: { sessionId: sessionData?.session ?? "" }, pause: !sessionData?.session })

	const [{ data: alertsData }] = useNewAlertSubscription()
	const toast = useToast()

	const [toastsShown, setToastsShown] = useState<string[]>([])

	useEffect(() => {
		if (alertsData?.newAlert && !toastsShown.includes(alertsData.newAlert._id) && alertsData.newAlert.type !== AlertTypes.RestrictedEntry) {
			setToastsShown((prev) => [...prev, alertsData.newAlert._id])

			toast({
				title: startCase(alertsData.newAlert.type),
				description: stripHtml(alertsData.newAlert.emailMessage).result,
				status: "warning",
				position: "top-right",
				duration: null,
				isClosable: true,
			})
		}

		return () => {
			setToastsShown([])
		}
	}, [alertsData])

	const { fetching: _fetching } = usePermissionsContext(true)

	const audioRef = useRef<HTMLAudioElement | null>(null)
	const [isSoundPlaying, setIsSoundPlaying] = useState(false)

	const playSound = () => {
		if (audioRef.current) {
			console.log("playing sound")
			audioRef.current
				.play()
				.then(() => setIsSoundPlaying(true))
				.catch((error) => console.error("Failed to play sound:", error))
		}
	}

	const pauseSound = () => {
		if (audioRef.current) {
			audioRef.current.pause()
			audioRef.current.currentTime = 0
			setIsSoundPlaying(false)
		}
	}

	const [userInteracted, setUserInteracted] = useState(false)
	const [isTestingSound, setIsTestingSound] = useState(false)

	const testPlaySound = () => {
		if (audioRef.current) {
			console.log("playing sound")
			audioRef.current
				.play()
				.then(() => setIsTestingSound(true))
				.catch((error) => console.error("Failed to play sound:", error))
		}
	}

	const testPauseSound = () => {
		if (audioRef.current) {
			audioRef.current.pause()
			audioRef.current.currentTime = 0
			setIsTestingSound(false)
		}
	}

	useEffect(() => {
		if (!userInteracted || isTestingSound) {
			if (toast.isActive("no-alert-sound")) toast.close("no-alert-sound")

			toast({
				id: "no-alert-sound",
				position: "top-right",
				duration: null,
				isClosable: false,
				render: () =>
					isTestingSound ? (
						<HStack w="full" align="stretch" p="4" bgColor="rgba(0,255,0, 0.2)" backdropFilter="blur(8px)" alignItems="center" rounded="xl" spacing={4}>
							<Icon as={CheckCircle} color="green.600" fontSize="xl" />
							<VStack w="full" align="flex-start">
								<Heading color="green.600" fontSize="sm">
									Alerts can play sound now.
								</Heading>

								<Button variant="outline" fontSize="sm" colorScheme="green" size="sm" onClick={testPauseSound}>
									Done
								</Button>
							</VStack>
						</HStack>
					) : (
						<HStack w="full" align="stretch" p="4" bgColor="rgba(255,0,0, 0.2)" backdropFilter="blur(8px)" alignItems="center" rounded="xl" spacing={4}>
							<Icon as={AlertCircle} color="red.600" fontSize="xl" />
							<VStack w="full" align="flex-start">
								<Heading color="red.600" fontSize="sm">
									Alerts can&apos;t play sound if you don&apos;t interact with the page.
								</Heading>

								<Button variant="outline" fontSize="sm" colorScheme="red" size="sm" onClick={testPlaySound}>
									Test now
								</Button>
							</VStack>
						</HStack>
					),
			})
		} else if (userInteracted && !isTestingSound) {
			toast.close("no-alert-sound")
		}
	}, [userInteracted, isTestingSound])

	const handleUserInteraction = () => {
		setUserInteracted(true)
		document.removeEventListener("click", handleUserInteraction)
		document.removeEventListener("touchstart", handleUserInteraction)
	}

	useEffect(() => {
		document.addEventListener("click", handleUserInteraction)
		document.addEventListener("touchstart", handleUserInteraction)

		return () => {
			document.removeEventListener("click", handleUserInteraction)
			document.removeEventListener("touchstart", handleUserInteraction)
		}
	}, [])

	if (fetching || _fetching) {
		return <SplashPage />
	}

	return (
		<ContextProvider>
			<VStack w="full" align="stretch">
				<audio ref={audioRef} src="/sounds/alarm.mp3" preload="auto" loop />

				<CheckEmailVerification />
				{data?.me ? (
					<VStack w="full">
						<RootRouter />
						<SosModal playSound={playSound} pauseSound={pauseSound} isSoundPlaying={isSoundPlaying} hasUserInteracted={userInteracted} />
						<RestrictedEntriesModal />
					</VStack>
				) : (
					<UnauthorizedRouter />
				)}
			</VStack>
		</ContextProvider>
	)
}
