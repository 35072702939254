import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"

import { UserLocationRecordFragment } from "../../graphql"
import { UserLocationListItem } from "./UserLocationListItem"

export type UserLocationsListProps = {
	userLocationRecords: UserLocationRecordFragment[]
	loadMore: () => void
}

export const UserLocationsList: React.FC<UserLocationsListProps> = ({ userLocationRecords, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2} maxH="xl" overflowY="auto">
			{userLocationRecords.map((userLocationRecord, i, arr) => (
				<React.Fragment key={userLocationRecord._id}>
					<UserLocationListItem userLocationRecord={userLocationRecord} />
					{i < arr.length - 1 && <Divider />}
				</React.Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
