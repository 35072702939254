import { Flex, Text } from "@chakra-ui/react"
import differenceInMinutes from "date-fns/differenceInMinutes"
import format from "date-fns/format"
import React from "react"
import { ServiceFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type ServiceDetailsProps = {
	service: ServiceFragment
}

export const ServiceDetails: React.FC<ServiceDetailsProps> = ({ service }) => {
	return (
		<Card title="Details">
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Key" value={service.key} />
				<Stat label="Status" value={service.isActive && differenceInMinutes(new Date(), new Date(service.lastHeartbeatAt)) < 5 ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				{service.lastHeartbeatAt && <Stat label="Last heartbeat at" value={format(new Date(service.lastHeartbeatAt), "MMM dd, yyyy p")} />}{" "}
				<Stat label="Last updated at" value={format(new Date(service.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(service.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
