import { Flex, Link, LinkBox, LinkOverlay, ListItem, Spinner, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { TagFragment, useUserByIdQuery } from "../../graphql"
import { Stat, UserTag } from "../common"

export type TagListItemProps = {
	tag: TagFragment
}

export const TagListItem: React.FC<TagListItemProps> = ({ tag }) => {
	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: tag.assignedToUserId ?? "" }, pause: !tag.assignedToUserId })

	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/tags/${tag._id}`} />

			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat
					label="User"
					value={
						tag.assignedToUserId ? (
							fetchingUser ? (
								<Spinner size="sm" />
							) : userError ? (
								<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
							) : !userData?.userById ? (
								<Text color="error.500">No user</Text>
							) : (
								<Link as={NavLink} to={`/users/${tag.assignedToUserId}`}>
									<UserTag user={userData.userById} />
								</Link>
							)
						) : (
							"Not assigned"
						)
					}
				/>
				<Stat label="UID" value={tag.tagDecimalId} />
				<Stat label="UID (HEX)" value={tag.tagHexId} />
				<Stat label="Status" value={tag.status} />
				<Stat label="Battery Status" value={tag.batteryStatus} />
				<Stat label="Last updated at" value={format(new Date(tag.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(tag.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
