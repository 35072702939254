import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import startCase from "lodash/startCase"
import React from "react"
import { AlertSourceTypes, useAlertSourceOfAlertFlowQuery } from "../../graphql"
import { AssetCategoriesList } from "../assetCategories/AssetCategoriesList"
import { AssetsList } from "../assets"
import { Card, Stat } from "../common"
import { UserGroupsList } from "../userGroups/UserGroupsList"
import { UsersList } from "../users/UsersList"
import { ZoneGroupsList } from "../zoneGroups/ZoneGroupsList"
import { ZonesList } from "../zones/ZonesList"

export type AlertFlowSourceProps = {
	alertFlowId: string
}

export const AlertFlowSource: React.FC<AlertFlowSourceProps> = ({ alertFlowId }) => {
	const [{ data, fetching, error }] = useAlertSourceOfAlertFlowQuery({ variables: { alertFlowId } })

	return (
		<Card title="Source">
			{fetching && !data?.alertSourceOfAlertFlow ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading alert source</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.alertSourceOfAlertFlow ? (
				<VStack w="full" align="stretch" p={4}>
					<Stat label="Type" value={startCase(data.alertSourceOfAlertFlow.type)} />

					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.Zones && data.alertSourceOfAlertFlow.zones?.length && <ZonesList zones={data.alertSourceOfAlertFlow.zones} />}
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.ZoneGroups && data.alertSourceOfAlertFlow.zoneGroups?.length && <ZoneGroupsList zoneGroups={data.alertSourceOfAlertFlow.zoneGroups} />}
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.Users && data.alertSourceOfAlertFlow.users?.length && <UsersList users={data.alertSourceOfAlertFlow.users} />}
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.UserGroups && data.alertSourceOfAlertFlow.userGroups?.length && <UserGroupsList userGroups={data.alertSourceOfAlertFlow.userGroups} />}
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.Assets && data.alertSourceOfAlertFlow.assets?.length && <AssetsList assets={data.alertSourceOfAlertFlow.assets} />}
					{data.alertSourceOfAlertFlow.type === AlertSourceTypes.AssetCategories && data.alertSourceOfAlertFlow.assetCategories?.length && <AssetCategoriesList assetCategories={data.alertSourceOfAlertFlow.assetCategories} />}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any alert source.
					</Text>
				</Center>
			)}
		</Card>
	)
}
