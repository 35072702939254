import { Center, chakra, Heading, LinkBox, LinkOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { DashboardTypes } from "../../constants"
import { BuildingFragment, FloorFragment, useActiveUserIdsQuery, useZonesInBuildingQuery } from "../../graphql"
import { useDashboardContext } from "../../hooks"

export type BuildingFloorItemUserRecordsProps = {
	floor: FloorFragment
	building: BuildingFragment
}

export const BuildingFloorItemUserRecords: React.FC<BuildingFloorItemUserRecordsProps> = ({ floor, building }) => {
	const { type } = useDashboardContext()

	const [{ data, fetching, error }] = useActiveUserIdsQuery({ variables: { isRestricted: type === DashboardTypes.CONFIDENTIALITY } })

	const [{ data: zonesData }] = useZonesInBuildingQuery({ variables: { buildingId: building._id } })

	const floorRecords = useMemo(
		() =>
			data?.activeUserIds.zoneWiseUsers
				.filter((z) =>
					zonesData?.zonesInBuilding
						?.filter((zone) => zone.floorId === floor._id)
						.map((z) => z._id)
						.includes(z.zoneId)
				)
				.flatMap((z) => z.userIds) || [],
		[JSON.stringify(data), JSON.stringify(zonesData), floor._id]
	)

	return (
		<VStack
			maxW="3xs"
			as={LinkBox}
			align="flex-start"
			px="6"
			py="4"
			m="1"
			bgColor="grayscale.background"
			transition="background-color, border-color .1s ease-in"
			borderWidth="4px"
			borderStyle="solid"
			borderColor={type === DashboardTypes.CONFIDENTIALITY ? (floorRecords.length ? "red.200" : "green.200") : floorRecords.length ? "green.200" : "red.200"}
			_hover={{ bgColor: "primary.50", borderColor: "primary.200" }}
			rounded="xl"
			cursor="pointer"
			justify="space-between"
		>
			<LinkOverlay as={NavLink} to={`/zones/buildings/${building._id}/floors/${floor._id}`} />
			<Heading fontSize="sm" color="grayscale.title">
				{floor.label.name}
			</Heading>

			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeUserIds ? (
				<VStack align="stretch">
					<Heading fontSize="2xl" color={type === DashboardTypes.CONFIDENTIALITY ? (floorRecords.length ? "red.500" : "green.500") : floorRecords.length ? "green.500" : "red.500"}>
						{floorRecords.length || 0}{" "}
						<chakra.span fontSize="sm" fontWeight="normal">
							users
						</chakra.span>
					</Heading>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
