import { Center, chakra, Heading, LinkBox, LinkOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { BuildingFragment, useActiveRestrictedEntryIdsQuery, useZonesInBuildingQuery } from "../../graphql"

export type BuildingWiseRestrictedEntriesItemProps = {
	building: BuildingFragment
}

export const BuildingWiseRestrictedEntriesItem: React.FC<BuildingWiseRestrictedEntriesItemProps> = ({ building }) => {
	const [{ data, fetching, error }] = useActiveRestrictedEntryIdsQuery()

	const [{ data: zonesData }] = useZonesInBuildingQuery({ variables: { buildingId: building._id } })

	const activeEntriesInBuilding = useMemo(() => data?.activeRestrictedEntryIds.zoneWiseRecordIds.filter((z) => zonesData?.zonesInBuilding.map((z) => z._id).includes(z.zoneId)).flatMap((z) => z.recordIds) || [], [data, zonesData])

	return (
		<VStack
			w="xs"
			maxW="3xs"
			as={LinkBox}
			align="flex-start"
			px="6"
			py="4"
			m="1"
			bgColor="grayscale.background"
			transition="background-color, border-color .1s ease-in"
			borderWidth="4px"
			borderStyle="solid"
			borderColor={activeEntriesInBuilding.length ? "red.500" : "green.100"}
			_hover={{ bgColor: "primary.50", borderColor: "primary.200" }}
			rounded="xl"
			cursor="pointer"
			justify="space-between"
		>
			<LinkOverlay as={NavLink} to={`/zones/buildings/${building._id}`} />
			<VStack align="flex-start" spacing="1">
				<Heading fontSize="sm" color="grayscale.title">
					{building.label.name}
				</Heading>
				<Text fontSize="xs" color="grayscale.label">
					{building.label.description}
				</Text>
			</VStack>

			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : activeEntriesInBuilding ? (
				<VStack align="stretch">
					<Heading fontSize="2xl" color={activeEntriesInBuilding.length ? "red.500" : "green.400"}>
						{activeEntriesInBuilding.length || 0}{" "}
						<chakra.span fontSize="sm" fontWeight="normal">
							users
						</chakra.span>
					</Heading>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
