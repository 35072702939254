import { Flex, ListItem, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React from "react"
import { SosRecordFragment } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Stat } from "../common"

export type UserSosRecordListItemProps = {
	sosRecord: SosRecordFragment
}

export const UserSosRecordListItem: React.FC<UserSosRecordListItemProps> = ({ sosRecord }) => {
	return (
		<ListItem>
			<VStack w="full" align="stretch" rounded="xl">
				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					<Stat label="Since" value={format(new Date(sosRecord.startAt), "MMM dd, yyyy p")} />
					<Stat label="Until" value={format(new Date(sosRecord.endAt), "MMM dd, yyyy p")} />
					<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(sosRecord.endAt), new Date(sosRecord.startAt)))} />
				</Flex>
			</VStack>
		</ListItem>
	)
}
