import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { UserAttendanceRecordFragment } from "../../graphql"
import { UserAttendanceListItem } from "./UserAttendanceListItem"

export type UserAttendancesListProps = {
	userAttendanceRecords: UserAttendanceRecordFragment[]
	loadMore: () => void
}

export const UserAttendancesList: React.FC<UserAttendancesListProps> = ({ userAttendanceRecords, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{userAttendanceRecords.map((userAttendanceRecord, i, arr) => (
				<React.Fragment key={userAttendanceRecord._id}>
					<UserAttendanceListItem userAttendanceRecord={userAttendanceRecord} />
					{i < arr.length - 1 && <Divider />}
				</React.Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
