import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { AssetCategoryFragment } from "../../graphql"
import { Stat } from "../common"

export type AssetCategoryListItemProps = {
	assetCategory: AssetCategoryFragment
}

export const AssetCategoryListItem: React.FC<AssetCategoryListItemProps> = ({ assetCategory }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/assets/categories/${assetCategory._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={assetCategory.label.name} />
				<Stat label="Last updated at" value={format(new Date(assetCategory.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(assetCategory.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
