import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { AlertTargetGroupFragment } from "../../graphql"
import { AlertTargetGroupListItem } from "./AlertTargetGroupListItem"

export type AlertTargetGroupsListProps = {
	alertTargetGroups: AlertTargetGroupFragment[]
	loadMore: () => void
}

export const AlertTargetGroupsList: React.FC<AlertTargetGroupsListProps> = ({ alertTargetGroups, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{alertTargetGroups.map((alertTargetGroup, i, arr) => (
				<Fragment key={alertTargetGroup._id}>
					<AlertTargetGroupListItem key={alertTargetGroup._id} alertTargetGroup={alertTargetGroup} />
					{i < arr.length - 1 && <Divider />}
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
