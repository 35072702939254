import { Button, ButtonGroup, Center, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { AssetFragment, PermissionTags, TagStatus, useTagByIdQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type AssetTagDetailsProps = {
	asset: AssetFragment
	disableActions?: boolean
}

export const AssetTagDetails: React.FC<AssetTagDetailsProps> = ({ asset, disableActions }) => {
	const [{ data: tagData, fetching: fetchingTag, error: tagError }] = useTagByIdQuery({ variables: { tagId: asset.tagId ?? "" }, pause: !asset.tagId })

	const { checkForPermissions } = useUserPermissions()

	const canAssign = useMemo(() => {
		if (asset.tagId) return false

		return checkForPermissions(PermissionTags.AssignTagToAsset)[0]
	}, [JSON.stringify(asset)])

	const canFlush = useMemo(() => {
		if (!asset.tagId) return false

		return checkForPermissions(PermissionTags.FlushTagFromAsset)[0]
	}, [JSON.stringify(asset)])

	const canDeactivate = useMemo(() => {
		if (!tagData?.tagById) return false
		if (!asset.tagId || tagData?.tagById?.status !== TagStatus.Active) return false

		return checkForPermissions(PermissionTags.DeactivateAssetTag)[0]
	}, [JSON.stringify(asset), JSON.stringify(tagData)])

	const canActivate = useMemo(() => {
		if (!tagData?.tagById) return false
		if (!asset.tagId || tagData?.tagById?.status !== TagStatus.Inactive) return false

		return checkForPermissions(PermissionTags.ActivateAssetTag)[0]
	}, [JSON.stringify(asset), JSON.stringify(tagData)])

	return (
		<Card
			title="Tag"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssign && (
							<Button as={NavLink} to={`/assets/${asset._id}/assignTag`} colorScheme="primary" size="sm">
								Assign
							</Button>
						)}
						{canFlush && (
							<Button as={NavLink} to={`/assets/${asset._id}/flushTag`} colorScheme="error" size="sm">
								Flush
							</Button>
						)}
						{canDeactivate && (
							<Button as={NavLink} to={`/assets/${asset._id}/deactivateTag`} colorScheme="error" size="sm">
								Deactivate
							</Button>
						)}
						{canActivate && (
							<Button as={NavLink} to={`/assets/${asset._id}/activateTag`} colorScheme="success" size="sm">
								Activate
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			{fetchingTag && !tagData?.tagById ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading tag</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : tagError ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{tagError.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : tagData?.tagById ? (
				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					<Stat label="UID" value={tagData.tagById.tagHexId || "Not available"} />
					<Stat
						label="Status"
						value={tagData.tagById.status === TagStatus.Active ? <Text color="success.600">Active</Text> : tagData.tagById.status === TagStatus.Inactive ? <Text color="error.600">Inactive</Text> : "Not available"}
					/>
					<Stat label="Battery Status" value={tagData.tagById.batteryStatus || "Not available"} />
					<Stat label="Assigned Since" value={tagData.tagById.assignedAt ? format(new Date(tagData.tagById.assignedAt), "MMM dd, yyyy p") : "Not available"} />
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any tag
					</Text>
				</Center>
			)}
		</Card>
	)
}
