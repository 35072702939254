import { Center, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds } from "date-fns"
import React from "react"
import { UserPresenceRecordStatus, useUserCurrentPresenceRecordQuery, useUserPresenceRecordUpdateSubscription } from "../../graphql"
import { getDurationInWords, transformDateTime } from "../../utils"
import { Card, Stat } from "../common"

export type UserCurrentPresenceProps = {
	userId: string
}

export const UserCurrentPresence: React.FC<UserCurrentPresenceProps> = ({ userId }) => {
	const [{ data, fetching, error }] = useUserCurrentPresenceRecordQuery({ variables: { userId } })
	useUserPresenceRecordUpdateSubscription({ variables: { userId } })

	return (
		<Card title="Current Presence">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading current presence</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userCurrentPresenceRecord && data.userCurrentPresenceRecord.status !== UserPresenceRecordStatus.Final ? (
				<VStack w="full" align="flex-start" p={4}>
					<Flex align="flex-start" flexWrap="wrap" gap={4}>
						<Stat label="Since" value={transformDateTime(new Date(data.userCurrentPresenceRecord.startAt))} />
						<Stat label="Until" value={transformDateTime(new Date(data.userCurrentPresenceRecord.endAt))} />
						<Stat label="Status" value={data.userCurrentPresenceRecord.status} />
						<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(data.userCurrentPresenceRecord.endAt), new Date(data.userCurrentPresenceRecord.startAt)))} />
					</Flex>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find current presence
					</Text>
				</Center>
			)}
		</Card>
	)
}
