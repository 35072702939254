import { Center, Input, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo, useState } from "react"
import { useLatestTagRecordsOfReaderQuery } from "../../graphql"
import { useQueryRefresher } from "../../hooks/useQueryRefresher"
import { Card } from "../common"
import { TagRecordsOfReaderList } from "./TagRecordsOfReaderList"

export type TagRecordsOfReaderProps = {
	readerId: string
}

export const TagRecordsOfReader: React.FC<TagRecordsOfReaderProps> = ({ readerId }) => {
	const [{ data, fetching, error }, refresh] = useLatestTagRecordsOfReaderQuery({ variables: { readerId } })

	useQueryRefresher(() => refresh({ requestPolicy: "network-only" }))

	const [searchTagHexId, setSearchTagHexId] = useState("")

	const records = useMemo(
		() => (searchTagHexId ? data?.latestTagRecordsOfReader.filter((o) => o.tagHexId.toLowerCase().includes(searchTagHexId.toLowerCase())) : data?.latestTagRecordsOfReader),
		[data?.latestTagRecordsOfReader, searchTagHexId]
	)

	return (
		<Card
			title="Tag records"
			alwaysShowAction
			action={
				<Input
					w="full"
					maxW="sm"
					size="sm"
					h="8"
					variant="filled"
					bgColor="yellowscale.input-background"
					placeholder="Search by tag UID (HEX)"
					_placeholder={{ color: "yellowscale.placeholer" }}
					value={searchTagHexId}
					onChange={(e) => setSearchTagHexId(e.target.value)}
				/>
			}
		>
			{fetching && !data?.latestTagRecordsOfReader ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.latestTagRecordsOfReader.length && records?.length ? (
				<VStack w="full" align="stretch">
					<TagRecordsOfReaderList records={records} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more records</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find records
					</Text>
				</Center>
			)}
		</Card>
	)
}
