import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import { startCase } from "lodash"
import React from "react"
import { NavLink } from "react-router-dom"
import { UserRoleGroupFragment } from "../../graphql"
import { Stat } from "../common"

export type UserRoleGroupListItemProps = {
	userRoleGroup: UserRoleGroupFragment
}

export const UserRoleGroupListItem: React.FC<UserRoleGroupListItemProps> = ({ userRoleGroup }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/userRoleGroups/${userRoleGroup._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={userRoleGroup.label.name} />
				<Stat label="Type" value={startCase(userRoleGroup.type)} />
				<Stat label="Last updated at" value={format(new Date(userRoleGroup.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(userRoleGroup.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
