import { Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React from "react"
import { AlertFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type AlertDetailsProps = {
	alert: AlertFragment
}

export const AlertDetails: React.FC<AlertDetailsProps> = ({ alert }) => {
	return (
		<Card title="Details">
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Type" value={startCase(alert.type)} />
				<Stat label="Created at" value={format(new Date(alert.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
