import { Button, ButtonGroup, Flex, Link, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { PermissionTags, useBuildingByIdQuery, ZoneFragment, ZoneTypes } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type ZoneDetailsProps = {
	zone: ZoneFragment
	disableActions?: boolean
}

export const ZoneDetails: React.FC<ZoneDetailsProps> = ({ zone, disableActions }) => {
	const { checkForSomePermissions } = useUserPermissions()

	const canUpdate = useMemo(() => checkForSomePermissions(PermissionTags.UpdateZone)[0], [])

	const [{ data: buildingData, fetching: fetchingBuilding, error: buildingError }] = useBuildingByIdQuery({
		variables: { buildingId: zone.buildingId ?? "" },
		pause: !zone.buildingId,
	})

	const floor = useMemo(() => buildingData?.buildingById?.floors.find((f) => f._id === zone.floorId), [zone.floorId, JSON.stringify(buildingData?.buildingById?.floors)])

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/zones/${zone._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Type" value={zone.type} />
				{zone.type === ZoneTypes.Indoor && (
					<>
						<Stat
							label="Building"
							value={
								zone.buildingId ? (
									fetchingBuilding ? (
										<Spinner size="sm" />
									) : buildingError ? (
										<Text color="error.500">{buildingError.message.replace("[GraphQL] ", "")}</Text>
									) : !buildingData?.buildingById ? (
										<Text color="error.500">No building</Text>
									) : (
										<Link as={NavLink} to={`/zones/buildings/${buildingData.buildingById._id}`}>
											{buildingData.buildingById.label.name}
										</Link>
									)
								) : (
									"No building"
								)
							}
						/>
						<Stat
							label="Floor"
							value={
								zone.buildingId ? (
									fetchingBuilding ? (
										<Spinner size="sm" />
									) : buildingError ? (
										<Text color="error.500">{buildingError.message.replace("[GraphQL] ", "")}</Text>
									) : !floor || !buildingData?.buildingById ? (
										<Text color="error.500">No floor</Text>
									) : (
										<Link as={NavLink} to={`/zones/buildings/${buildingData.buildingById._id}/floors/${floor._id}`}>
											{floor.label.name}
										</Link>
									)
								) : (
									"No floor"
								)
							}
						/>
					</>
				)}
				<Stat label="Last updated at" value={format(new Date(zone.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(zone.createdAt), "MMM dd, yyyy p")} />{" "}
			</Flex>
		</Card>
	)
}
