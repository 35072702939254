import { Flex, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertResolutionFragment, useUserByIdQuery } from "../../graphql"
import { Card, Stat, UserTag } from "../common"

export type AlertResolutionProps = {
	resolution: AlertResolutionFragment
}

export const AlertResolution: React.FC<AlertResolutionProps> = ({ resolution }) => {
	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: resolution.lastUpdatedByUserId || "" } })

	return (
		<Card title="Resolution">
			<VStack p={4}>
				<Text fontSize="sm" fontStyle="italic">
					&quot;{resolution.remarks}&quot;
				</Text>
			</VStack>
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat
					label="User"
					value={
						fetchingUser ? (
							<Spinner size="sm" />
						) : userError ? (
							<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
						) : !userData?.userById ? (
							<Text color="error.500">No user</Text>
						) : (
							<Link as={NavLink} to={`/users/${resolution.lastUpdatedByUserId}`}>
								<UserTag user={userData.userById} />
							</Link>
						)
					}
				/>
				<Stat label="Resolved at" value={format(new Date(resolution.lastUpdatedAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
