import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { ReaderFragment } from "../../graphql"
import { ReaderListItem } from "./ReaderListItem"

export type ReadersListProps = {
	readers: ReaderFragment[]
	zoneId?: string
	loadMore?: () => void
}

export const ReadersList: React.FC<ReadersListProps> = ({ readers, zoneId, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{readers.map((reader, i, arr) => (
				<Fragment key={reader._id}>
					<ReaderListItem key={reader._id} reader={reader} zoneId={zoneId} />
					{i < arr.length - 1 && <Divider />}
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
