import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useCallback, useState } from "react"
import DatePicker from "react-date-picker"
import { UserPastLocationRecordsQueryVariables, useUserPastLocationRecordsQuery } from "../../graphql"
import { Card } from "../common"
import { UserLocationsList } from "./UserLocationsList"

export type UserPastLocationsProps = {
	userId: string
}

export const UserPastLocations: React.FC<UserPastLocationsProps> = ({ userId }) => {
	const [date, setDate] = useState(new Date(format(new Date(), "MM/dd/yyyy")))
	const [pagination, setPagination] = useState<UserPastLocationRecordsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useUserPastLocationRecordsQuery({ variables: { userId, pagination, date } })

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.userPastLocationRecords.hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<Card title="Past Locations" alwaysShowAction action={<DatePicker onChange={setDate as any} value={date} format="MMM dd, y" maxDate={new Date()} clearIcon={null} />}>
			{fetching && !data?.userPastLocationRecords ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading past locations</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userPastLocationRecords.userLocationRecords.length ? (
				<VStack w="full" align="stretch">
					<UserLocationsList userLocationRecords={data.userPastLocationRecords.userLocationRecords} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more records</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find past locations
					</Text>
				</Center>
			)}
		</Card>
	)
}
