/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Text, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import DatePicker from "react-date-picker"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { UserFragment, UpdateVisitorDetailsMutationVariables, useUpdateVisitorDetailsMutation, useZoneGroupsQuery } from "../graphql"

type UpdateVisitorDetailsFormValues = UpdateVisitorDetailsMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UpdateVisitorDetailsFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	visitDate: yup.date().required().label("Visit Date"),
	companyName: yup.string().nullable().label("Company Name"),
	level: yup.string().nullable().label("Level"),
	visitorCardNo: yup.string().nullable().label("Visitor Card Number"),
	buildingName: yup.string().nullable().label("Building Name"),
})

export type UpdateVisitorDetailsFormProps = {
	user: UserFragment
}

export const UpdateVisitorDetailsForm: React.FC<UpdateVisitorDetailsFormProps> = ({ user }) => {
	const initialValues: UpdateVisitorDetailsFormValues = {
		visitDate: new Date(),
		companyName: user.visitorDetails?.companyName,
		level: user.visitorDetails?.level,
		visitorCardNo: user.visitorDetails?.visitorCardNo,
		buildingName: user.visitorDetails?.buildingName,
	}

	const [{ fetching }, updateVisitorDetails] = useUpdateVisitorDetailsMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: UpdateVisitorDetailsFormValues) => {
		const { data, error } = await updateVisitorDetails({
			userId: user._id,
			input: { ...values },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateVisitorDetails) {
			navigate(`/users/${data.updateVisitorDetails._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateVisitorDetailsFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: zoneGroupsData, error: zoneGroupsError, fetching: zoneGroupsFetching }] = useZoneGroupsQuery()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.companyName && formik.errors.companyName)}>
					<FormLabel fontWeight="bold">Visitor Company Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter visitor company name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("companyName")} />

					<FormErrorMessage>{formik.errors.companyName}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.level && formik.errors.level)}>
					<FormLabel fontWeight="bold">Level</FormLabel>

					{zoneGroupsFetching ? (
						<Text>Fetching zone groups</Text>
					) : zoneGroupsError ? (
						<VStack>
							<Text>Couldn&apos;t fetch zone groups</Text>
							<Text>{zoneGroupsError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !zoneGroupsData?.zoneGroups.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch zone groups</Text>
						</VStack>
					) : (
						<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Level" {...formik.getFieldProps("level")}>
							{zoneGroupsData.zoneGroups.map((zoneGroup) => (
								<option key={zoneGroup._id} style={{ backgroundColor: "transparent" }} value={zoneGroup.label.name}>
									{zoneGroup.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.level}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.visitorCardNo && formik.errors.visitorCardNo)}>
					<FormLabel fontWeight="bold">Visitor Card Number</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter visitor card number" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("visitorCardNo")} />

					<FormErrorMessage>{formik.errors.visitorCardNo}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.buildingName && formik.errors.buildingName)}>
					<FormLabel fontWeight="bold">Building</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter building name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("buildingName")} />

					<FormErrorMessage>{formik.errors.buildingName}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.visitDate && formik.errors.visitDate)} pt="2">
					<DatePicker onChange={(date) => formik.setFieldValue("visitDate", date)} value={formik.values.visitDate} format="dd/MM/yyyy" maxDate={new Date()} clearIcon={null} />
					<FormErrorMessage>{formik.errors.visitDate as string}</FormErrorMessage>
				</FormControl>
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Update
			</Button>
		</VStack>
	)
}
