import { Flex, LinkBox, LinkOverlay, ListItem, Text } from "@chakra-ui/react"
import differenceInMinutes from "date-fns/differenceInMinutes"
import format from "date-fns/format"
import React from "react"
import { NavLink } from "react-router-dom"
import { ServiceFragment } from "../../graphql"
import { Stat } from "../common"

export type ServiceListItemProps = {
	service: ServiceFragment
}

export const ServiceListItem: React.FC<ServiceListItemProps> = ({ service }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/services/${service._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={service.label.name} />
				<Stat label="Key" value={service.key} />
				<Stat label="Status" value={service.isActive && differenceInMinutes(new Date(), new Date(service.lastHeartbeatAt)) < 5 ? <Text color="success.600">Active</Text> : <Text color="error.600">Inactive</Text>} />
				{service.lastHeartbeatAt && <Stat label="Last heartbeat at" value={format(new Date(service.lastHeartbeatAt), "MMM dd, yyyy p")} />}{" "}
				<Stat label="Last updated at" value={format(new Date(service.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(service.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
