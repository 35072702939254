import { Button, Flex, Heading, Link, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React, { useEffect, useMemo, useState } from "react"
import { NavLink } from "react-router-dom"
import { AlertFragment, AlertTypes, useNewAlertSubscription, useRestrictedUserLocationRecordsUpdateSubscription, UserLocationRecordStatus, useUserByIdQuery, useUserLocationRecordByIdQuery, useZoneByIdQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { UserTag } from "../common"
import { Stat } from "../common/Stat"

export const RestrictedEntriesModal: React.FC = () => {
	const [alerts, setAlerts] = useState<AlertFragment[]>([])

	const [{ data }] = useNewAlertSubscription()

	const recordIds = useMemo(() => alerts.map((a) => a.userLocationRecordId).filter(Boolean) as string[], [alerts])

	const [{ data: restrictedEntriesData }] = useRestrictedUserLocationRecordsUpdateSubscription({ variables: { recordIds }, pause: !recordIds.length })

	useEffect(() => {
		if (data?.newAlert.type === AlertTypes.RestrictedEntry && !alerts.find((a) => a.userLocationRecordId === data.newAlert.userLocationRecordId)) {
			setAlerts((prev) => [data.newAlert, ...(prev || [])])
		}
	}, [data])

	useEffect(() => {
		if (
			restrictedEntriesData?.restrictedUserLocationRecordsUpdate &&
			(!restrictedEntriesData.restrictedUserLocationRecordsUpdate.isRestricted || restrictedEntriesData.restrictedUserLocationRecordsUpdate.status === UserLocationRecordStatus.Final) &&
			alerts.find((alert) => alert.userLocationRecordId === restrictedEntriesData.restrictedUserLocationRecordsUpdate._id)
		) {
			const _alerts = [...alerts]
			_alerts.splice(
				alerts.findIndex((alert) => alert._id === restrictedEntriesData.restrictedUserLocationRecordsUpdate._id),
				1
			)

			setAlerts(_alerts)
		}
	}, [restrictedEntriesData])

	const [isOpen, setIsOpen] = useState((alerts.length || 0) > 0)

	const alarm = useMemo(() => new Audio("/sounds/alarm.mp3"), [])

	const [acknowledgedIds, setAcknowledgedIds] = useState<string[]>([])

	useEffect(() => {
		if ((alerts.filter(({ _id }) => !acknowledgedIds.includes(_id)).length || 0) > 0 && isOpen && alarm.paused) {
			alarm.play()
		} else {
			if (!alarm.paused) alarm.pause()
		}

		return () => {
			if (!alarm.paused) alarm.pause()
		}
	}, [alerts, isOpen, acknowledgedIds])

	useEffect(() => {
		if ((alerts.filter(({ _id }) => !acknowledgedIds.includes(_id)).length || 0) > 0 && !isOpen) {
			setIsOpen(true)
		}

		if ((alerts.filter(({ _id }) => !acknowledgedIds.includes(_id)).length || 0) == 0 && isOpen) {
			setIsOpen(false)
		}

		return () => {
			if (isOpen) setIsOpen(false)
		}
	}, [alerts, acknowledgedIds])

	return (
		<Modal size="2xl" isCentered isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnOverlayClick={false} closeOnEsc={false}>
			<ModalOverlay bgColor="rgba(255,0,0,0.2)">
				<ModalContent>
					<ModalBody>
						<VStack w="full" p="4">
							<Heading fontSize="lg" color="error.800">
								Restricted Entries
							</Heading>

							<VStack w="full" align="stretch" maxH="xs" p="2" overflow="auto">
								{alerts.filter(({ _id }) => !acknowledgedIds.includes(_id)).map((alert) => (alert.userLocationRecordId ? <Item key={alert._id} userLocationRecordId={alert.userLocationRecordId} /> : <></>))}
							</VStack>

							<Button colorScheme="error" size="sm" onClick={() => setAcknowledgedIds(alerts.map((o) => o._id) || [])}>
								Acknowledge
							</Button>
						</VStack>
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	)
}

const Item: React.FC<{ userLocationRecordId: string }> = ({ userLocationRecordId }) => {
	const [{ data: userLocationRecordData, fetching: fetchingUserLocationRecord, error: userLocationRecordError }] = useUserLocationRecordByIdQuery({ variables: { userLocationRecordId } })

	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({
		variables: { userId: userLocationRecordData?.userLocationRecordById?.userId || "" },
		pause: !userLocationRecordData?.userLocationRecordById?.userId,
	})

	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({
		variables: { zoneId: userLocationRecordData?.userLocationRecordById?.zoneId || "" },
		pause: !userLocationRecordData?.userLocationRecordById?.zoneId,
	})

	if (fetchingUserLocationRecord) return <Spinner />

	if (userLocationRecordError) return <Text color="error.500">{userLocationRecordError.message.replace("[GraphQL] ", "")}</Text>

	if (!userLocationRecordData?.userLocationRecordById) return <Text color="error.500">No record</Text>

	return (
		<Flex>
			<Stat
				label="User"
				value={
					fetchingUser ? (
						<Spinner size="sm" />
					) : userError ? (
						<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
					) : !userData?.userById ? (
						<Text color="error.500">No user</Text>
					) : (
						<Link as={NavLink} to={`/users/${userData.userById._id}`}>
							<UserTag user={userData.userById} />
						</Link>
					)
				}
			/>
			<Stat
				label="Zone"
				value={
					fetchingZone ? (
						<Spinner size="sm" />
					) : zoneError ? (
						<Text color="error.500">{zoneError.message.replace("[GraphQL] ", "")}</Text>
					) : !zoneData?.zoneById ? (
						<Text color="error.500">No zone</Text>
					) : (
						<Link as={NavLink} to={`/zones/${zoneData.zoneById._id}`}>
							{zoneData.zoneById.label.name}
						</Link>
					)
				}
			/>
			<Stat label="Since" value={format(new Date(userLocationRecordData.userLocationRecordById.startAt), "MMM dd, yyyy p")} />
			<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(userLocationRecordData.userLocationRecordById.endAt), new Date(userLocationRecordData.userLocationRecordById.startAt)))} />
		</Flex>
	)
}
