import { Flex, Link, LinkBox, LinkOverlay, ListItem, Spinner, Text } from "@chakra-ui/react"
import { format } from "date-fns"
import { startCase } from "lodash"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { useBuildingByIdQuery, ZoneFragment } from "../../graphql"
import { Stat } from "../common"

export type ZoneListItemProps = {
	zone: ZoneFragment
}

export const ZoneListItem: React.FC<ZoneListItemProps> = ({ zone }) => {
	const [{ data, fetching, error }] = useBuildingByIdQuery({
		variables: { buildingId: zone.buildingId ?? "" },
		pause: !zone.buildingId,
	})

	const floor = useMemo(() => data?.buildingById?.floors.find((f) => f._id === zone.floorId), [zone.floorId, JSON.stringify(data?.buildingById?.floors)])

	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/zones/${zone._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={zone.label.name} />
				<Stat label="Type" value={startCase(zone.type)} />

				{zone.buildingId && (
					<Stat
						label="Building"
						value={
							fetching ? (
								<Spinner size="sm" />
							) : error ? (
								<Text color="error.500">{error.message.replace("[GraphQL] ", "")}</Text>
							) : !data?.buildingById ? (
								<Text color="error.500">No building</Text>
							) : (
								<Link as={NavLink} to={`/zones/buildings/${zone.buildingId}`}>
									<Text fontSize="sm">{data.buildingById.label.name}</Text>
								</Link>
							)
						}
					/>
				)}
				{zone.buildingId && zone.floorId && (
					<Stat
						label="Floor"
						value={
							fetching ? (
								<Spinner size="sm" />
							) : error ? (
								<Text color="error.500">{error.message.replace("[GraphQL] ", "")}</Text>
							) : !data?.buildingById || !floor ? (
								<Text color="error.500">No floor</Text>
							) : (
								<Link as={NavLink} to={`/zones/buildings/${zone.buildingId}/floors/${zone.floorId}`}>
									<Text fontSize="sm">{floor.label.name}</Text>
								</Link>
							)
						}
					/>
				)}
				<Stat label="Last updated at" value={format(new Date(zone.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(zone.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
