import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react"
import React from "react"
import { AssignTagByDecimalIdForm, AssignTagForm } from "../../forms"
import { UserFragment } from "../../graphql"

export type AssignTagProps = {
	user: UserFragment
}

export const AssignTag: React.FC<AssignTagProps> = ({ user }) => {
	return (
		<Tabs flex={1} size="sm" variant="soft-rounded" colorScheme="primary" isLazy lazyBehavior="unmount">
			<TabList w="fit-content" bgColor="primary.50" p={1} rounded="2xl">
				<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
					By Device
				</Tab>
				<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
					By Tag Id
				</Tab>
			</TabList>
			<TabPanels>
				<TabPanel as={VStack} w="full" align="stretch" px="0">
					<AssignTagForm user={user} />
				</TabPanel>
				<TabPanel as={VStack} w="full" align="stretch" px="0">
					<AssignTagByDecimalIdForm user={user} />
				</TabPanel>
			</TabPanels>
		</Tabs>
	)
}
