import { Button, ButtonGroup, Flex } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { BuildingFragment, PermissionTags } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type BuildingDetailsProps = {
	building: BuildingFragment
	disableActions?: boolean
}

export const BuildingDetails: React.FC<BuildingDetailsProps> = ({ building, disableActions }) => {
	const { checkForSomePermissions } = useUserPermissions()

	const canUpdate = useMemo(() => checkForSomePermissions(PermissionTags.UpdateBuilding)[0], [])

	const canDelete = useMemo(() => checkForSomePermissions(PermissionTags.DeleteBuilding)[0], [])

	return (
		<Card
			title="Details"
			action={
				!disableActions && (
					<ButtonGroup>
						{canUpdate && (
							<Button as={NavLink} to={`/zones/buildings/${building._id}/update`} colorScheme="primary" size="sm">
								Update
							</Button>
						)}
						{canDelete && (
							<Button as={NavLink} to={`/zones/buildings/${building._id}/delete`} colorScheme="error" size="sm">
								Delete
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Floors" value={building.floors.length || 0} />
				<Stat label="Last updated at" value={format(new Date(building.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(building.createdAt), "MMM dd, yyyy p")} />{" "}
			</Flex>
		</Card>
	)
}
