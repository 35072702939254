import { Alert, AlertDescription, AlertIcon, Badge, Center, HStack, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react"
import React, { useEffect, useMemo } from "react"
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import { UserActiveSosRecord, UserCurrentLocation, UserPastLocations, UserPastSosRecords, UserPermissionsGroups, UserProfile, UserRoleDetails, UserTag, UserTagDetails, VisitorDetails } from "../../components"
import { Header } from "../../components/common/Header"
import { UserActiveAttendance, UserPastAttendances } from "../../components/userAttendance"
import { PermissionTags, useActiveSosRecordOfUserQuery, useMeQuery, UserRoleTypes, useUserByIdQuery, useUserTypeByIdQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"

const UserPage: React.FC = () => {
	const { userId = "" } = useParams<{ userId: string }>()

	const [{ data: meData }] = useMeQuery()

	const navigate = useNavigate()

	useEffect(() => {
		if (meData?.me?._id === userId) {
			navigate("/profile", { replace: true })
		}
	}, [meData, userId])

	const [{ data, fetching, error }] = useUserByIdQuery({ variables: { userId }, pause: !userId || meData?.me?._id === userId })

	const { checkForPermissions } = useUserPermissions()

	const canReadUserPermissionsGroups = useMemo(() => {
		if (!data?.userById || !meData?.me) return true

		if (data.userById.roleType === UserRoleTypes.Admin) {
			return checkForPermissions(PermissionTags.ReadAdminUserPermissionsGroup)[0]
		}

		if (data.userById.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.ReadShortTermUserPermissionsGroup)[0]
		}

		return checkForPermissions(PermissionTags.ReadWorkingUserPermissionsGroup)[0]
	}, [data, meData])

	const { pathname } = useLocation()

	const [{ data: typeData }] = useUserTypeByIdQuery({ variables: { userTypeId: data?.userById?.typeId ?? "" }, pause: !data?.userById?.typeId })

	const [{ data: activeSosData }] = useActiveSosRecordOfUserQuery({ variables: { userId } })

	return (
		<>
			<Header title="User" />
			<VStack w="full" align="stretch" p={4} m="0 !important" spacing={4}>
				<VStack w="full" align="stretch">
					{fetching ? (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading user</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					) : error ? (
						<Center py="4">
							<Text fontSize="sm" fontWeight="semibold" color="error.500">
								{error.message.replace("[GraphQL] ", "")}
							</Text>
						</Center>
					) : data?.userById ? (
						<>
							<Stack w="full" direction={{ base: "column", xl: "row" }}>
								<VStack flex={1} maxW={{ base: "full", xl: "2xl" }} align="stretch" spacing={2}>
									{activeSosData?.activeSosRecordOfUser && (
										<Alert status="error" variant="solid" rounded="xl" gap={4}>
											<AlertIcon />
											<AlertDescription>The user has raised an SOS alert.</AlertDescription>
										</Alert>
									)}
									<HStack align="flex-start" py="0.5">
										<UserTag user={data.userById} allowAvatarChange />
										{data.userById.isDepartmentHead && (
											<Badge px="2" py="1" variant="subtle" rounded="full" color="green.600" colorScheme="green">
												Department Head
											</Badge>
										)}
									</HStack>

									{data.userById.roleType !== UserRoleTypes.Admin && <UserTagDetails user={data.userById} />}
									<UserProfile user={data.userById} />
									{typeData?.userTypeById.label.name === "Visitor" && <VisitorDetails user={data.userById} />}
									{data.userById.roleType !== UserRoleTypes.Admin && <UserRoleDetails user={data.userById} />}

									{canReadUserPermissionsGroups && <UserPermissionsGroups user={data.userById} />}
								</VStack>
								{data.userById.roleType !== UserRoleTypes.Admin && (
									<Tabs flex={1} size="sm" variant="soft-rounded" colorScheme="primary" isLazy lazyBehavior="unmount">
										<TabList w="fit-content" bgColor="primary.50" p={1} rounded="2xl">
											<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
												Location
											</Tab>
											<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
												Attendance
											</Tab>
											<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
												SOS
											</Tab>
											<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl" as={NavLink} to={`/map/searchUser?userId=${data.userById._id}&trail=1&from=${pathname}`}>
												Map
											</Tab>
										</TabList>
										<TabPanels w="full">
											<TabPanel as={VStack} w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4} px="0" py="2">
												<UserCurrentLocation userId={data.userById._id} />
												<UserPastLocations userId={data.userById._id} />
											</TabPanel>
											<TabPanel as={VStack} w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4} px="0" py="2">
												<UserActiveAttendance userId={data.userById._id} />
												<UserPastAttendances userId={data.userById._id} />
											</TabPanel>
											<TabPanel as={VStack} w="full" maxW={{ base: "full", xl: "xl" }} align="stretch" spacing={4} px="0" py="2">
												<UserActiveSosRecord userId={data.userById._id} />
												<UserPastSosRecords userId={data.userById._id} />
											</TabPanel>
										</TabPanels>
									</Tabs>
								)}
							</Stack>
						</>
					) : (
						<Center py="4">
							<Text fontSize="sm" fontWeight="semibold" color="error.500">
								Couldn&apos;t find the user.
							</Text>
						</Center>
					)}
				</VStack>
			</VStack>
		</>
	)
}

export default UserPage
