import { Center, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { TodaySosRecordsQueryVariables, useTodaySosRecordsQuery } from "../../graphql"
import { SosRecordsList } from "./SosRecordsList"

export const AllTodaySosRecords: React.FC = () => {
	const [pagination, setPagination] = useState<TodaySosRecordsQueryVariables["pagination"]>({ limit: 20, page: 1 })

	const [{ data, fetching, error }] = useTodaySosRecordsQuery({
		variables: { pagination },
	})

	const nextPage = useCallback(() => {
		if (fetching) return

		if (data?.todaySosRecords.hasNextPage && !fetching) {
			setPagination((prev) => ({
				...prev,
				page: (prev.page || 0) + 1,
			}))
		}
	}, [data, fetching])

	return (
		<VStack flex="1" align="stretch" spacing={4} maxW="2xl">
			{fetching && !data?.todaySosRecords ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.todaySosRecords.sosRecords && data.todaySosRecords.sosRecords.length ? (
				<VStack w="full" align="stretch">
					<Text>
						Showing {data.todaySosRecords.sosRecords.length || 0}
						{(data.todaySosRecords.sosRecords.length || 0) > 1 ? " records" : " record"}
					</Text>

					<SosRecordsList sosRecords={data.todaySosRecords.sosRecords} loadMore={nextPage} />

					{fetching && (
						<Center w="full" py="4">
							<VStack w="full" color="grayscale.label">
								<Text fontSize="sm">Loading more users</Text>
								<Spinner size="sm" />
							</VStack>
						</Center>
					)}
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
