import { Flex, Link, ListItem, Spinner, Text } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import React from "react"
import { NavLink } from "react-router-dom"
import { useDepartmentByIdQuery, UserLocationRecordFragment, UserLocationRecordStatus, useUserByIdQuery } from "../../graphql"
import { getDurationInWords, transformDateTime } from "../../utils"
import { Stat, UserTag } from "../common"

export type ZonedUserLocationListItemProps = {
	userLocationRecord: UserLocationRecordFragment
}

export const ZonedUserLocationListItem: React.FC<ZonedUserLocationListItemProps> = ({ userLocationRecord }) => {
	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: userLocationRecord.userId } })
	const [{ data: departmentData, fetching: fetchingDepartment, error: departmentError }] = useDepartmentByIdQuery({ variables: { departmentId: userData?.userById?.departmentId ?? "" }, pause: !userData?.userById?.departmentId })

	return (
		<ListItem bgColor={userLocationRecord.isRestricted ? "red.100" : "transparent"}>
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat
					label="User"
					value={
						fetchingUser ? (
							<Spinner size="sm" />
						) : userError ? (
							<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
						) : !userData?.userById ? (
							<Text color="error.500">No user</Text>
						) : (
							<Link as={NavLink} to={`/users/${userLocationRecord.userId}`}>
								<UserTag user={userData.userById} />
							</Link>
						)
					}
				/>
				{userData?.userById?.departmentId && (
					<Stat
						label="Department"
						value={
							fetchingDepartment ? (
								<Spinner size="sm" />
							) : departmentError ? (
								<Text color="error.500">{departmentError.message.replace("[GraphQL] ", "")}</Text>
							) : !departmentData?.departmentById ? (
								<Text color="error.500">No department</Text>
							) : (
								<Link as={NavLink} to={`/departments/${userData.userById.departmentId}`}>
									{departmentData.departmentById.label.name}
								</Link>
							)
						}
					/>
				)}
				<Stat label="Since" value={transformDateTime(new Date(userLocationRecord.startAt))} />
				<Stat label="Until" value={transformDateTime(new Date(userLocationRecord.endAt))} />
				<Stat
					label="Status"
					value={
						<Text color={userLocationRecord.status === UserLocationRecordStatus.Active ? "green.500" : userLocationRecord.status === UserLocationRecordStatus.Stale ? "yellow.500" : "red.500"}>{userLocationRecord.status}</Text>
					}
				/>
				<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(userLocationRecord.endAt), new Date(userLocationRecord.startAt)))} />
			</Flex>
		</ListItem>
	)
}
