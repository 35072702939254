import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { ReaderStatusLogFragment } from "../../graphql"
import { ReaderStatusLogListItem } from "./ReaderStatusLogListItem"

export type ReaderStatusLogsListProps = {
	logs: ReaderStatusLogFragment[]
	loadMore: () => void
}

export const ReaderStatusLogsList: React.FC<ReaderStatusLogsListProps> = ({ logs, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{logs.map((log, i, arr) => (
				<Fragment key={log._id}>
					<ReaderStatusLogListItem key={log._id} log={log} />
					{i < arr.length - 1 && <Divider />}
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
