import { Flex } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { TileFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type TileDetailsProps = {
	tile: TileFragment
}

export const TileDetails: React.FC<TileDetailsProps> = ({ tile }) => {
	return (
		<Card title="Details">
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Levels" value={tile.levelWiseZoneEmbeddings.length} />
				<Stat label="Embedded Zones" value={tile.levelWiseZoneEmbeddings.map((o) => o.zoneEmbeddings.length).reduce((acc, value) => acc + value, 0)} />
				<Stat label="Last updated at" value={format(new Date(tile.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(tile.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
