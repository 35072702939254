import { Center, Divider, List, Spinner, Text, VStack } from "@chakra-ui/react"
import React from "react"
import { useAlertFlowsOfAlertQuery } from "../../graphql"
import { AlertFlowListItem } from "../alertFlows/AlertFlowListItem"
import { Card } from "../common"

export type AlertFlowsOfAlertProps = {
	alertId: string
}

export const AlertFlowsOfAlert: React.FC<AlertFlowsOfAlertProps> = ({ alertId }) => {
	const [{ data, fetching, error }] = useAlertFlowsOfAlertQuery({ variables: { alertId } })

	return (
		<Card title="Alert Flows">
			{fetching && !data?.alertFlowsOfAlert ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading alert flows</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.alertFlowsOfAlert.length ? (
				<List spacing={2}>
					{data.alertFlowsOfAlert.map((flow, i, arr) => (
						<>
							<AlertFlowListItem key={flow._id} alertFlow={flow} />
							{i < arr.length - 1 && <Divider />}
						</>
					))}
				</List>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any alert flows.
					</Text>
				</Center>
			)}
		</Card>
	)
}
