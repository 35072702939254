import { Flex, ListItem } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { TagRecordFragment } from "../../graphql"
import { Stat } from "../common"

export type TagRecordsOfReaderListItemProps = {
	record: TagRecordFragment
}

const hexToDec = (hex: string) => parseInt(hex, 16).toString(10)

export const TagRecordsOfReaderListItem: React.FC<TagRecordsOfReaderListItemProps> = ({ record }) => {
	const { isSOSAlarmOn, isLowBatteryAlarmOn } = useMemo(() => {
		const tagStatusBinary = parseInt(record.tagStatus).toString(2).padStart(8, "0")

		// if 7th bit is 1, SOS Alarm is on; of 0th bit is 1, Low Voltage Alarm is on.
		const isSOSAlarmOn = Number(tagStatusBinary[0]) === 1
		const isLowBatteryAlarmOn = Number(tagStatusBinary[tagStatusBinary.length - 1]) === 1

		return { isSOSAlarmOn, isLowBatteryAlarmOn }
	}, [record.tagStatus])

	return (
		<ListItem>
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Reader UID" value={hexToDec(record.readerHexId)} />
				<Stat label="Reader UID (HEX)" value={record.readerHexId} />
				<Stat label="Tag UID" value={hexToDec(record.tagHexId)} />
				<Stat label="Tag UID (HEX)" value={record.tagHexId} />
				<Stat label="Tag Battery" value={isLowBatteryAlarmOn ? "Low" : "Normal"} />
				<Stat label="Tag SOS" value={isSOSAlarmOn ? "ON" : "OFF"} />
				<Stat label="Tag Type" value={record.tagType} />
				<Stat label="RSSI" value={record.rssi} />
				<Stat label="Timestamp" value={format(new Date(record.timestamp), "MMM dd, yyyy pp")} />
			</Flex>
		</ListItem>
	)
}
