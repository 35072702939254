/* eslint-disable @typescript-eslint/indent */
import {
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { format } from "date-fns"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { UsersSelector } from "../components"
import { GenerateUserAttendanceReportMutationVariables, useGenerateUserAttendanceReportMutation } from "../graphql"

type GenerateUserAttendanceReportFormValues = GenerateUserAttendanceReportMutationVariables["input"]

const validationSchema = yup.object<Record<keyof GenerateUserAttendanceReportFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	userIds: yup.array().of(yup.string()).min(1).required().label("Users"),
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	workingHours: yup.number().min(1).label("Working Hours"),
	sinceDate: yup.date().required().label("Since Date"),
	untilDate: yup.date().required().label("Until Date"),
	usersFilter: yup.object({
		keyword: yup.string().nullable(),
		departmentId: yup.string().nullable(),
		userTypeId: yup.string().nullable(),
		isAllSelected: yup.boolean().nullable(),
	}),
})

const initialValues: GenerateUserAttendanceReportFormValues = {
	userIds: [],
	label: {
		name: "",
		description: "",
	},
	workingHours: 12,
	sinceDate: new Date(),
	untilDate: new Date(),
	usersFilter: {
		keyword: "",
		departmentId: "",
		userTypeId: "",
		isAllSelected: false,
	},
}

export const GenerateUserAttendanceReportForm: React.FC = () => {
	const [{ fetching }, generateUserAttendanceReport] = useGenerateUserAttendanceReportMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: GenerateUserAttendanceReportFormValues) => {
		const { data, error } = await generateUserAttendanceReport({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.generateUserAttendanceReport) {
			navigate(`/reports/past/${data.generateUserAttendanceReport._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<GenerateUserAttendanceReportFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch" maxW="xl">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)}>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
				<Popover>
					<PopoverTrigger>
						{formik.values.sinceDate && formik.values.untilDate && (
							<FormControl>
								<FormLabel fontWeight="bold">Date Range</FormLabel>

								<Input variant="filled" bgColor="grayscale.input-background" value={`${format(formik.values.sinceDate, "MMM dd, yyyy")} - ${format(formik.values.untilDate, "MMM dd, yyyy")}`} isReadOnly />
							</FormControl>
						)}
					</PopoverTrigger>
					<PopoverContent w="full" _focus={{ shadow: "none" }}>
						<PopoverBody w="full" p="0">
							<DateRange
								editableDateInputs={true}
								onChange={(item) => {
									if (item?.selection.startDate && item?.selection.endDate) {
										formik.setFieldValue("sinceDate", new Date(item.selection.startDate))
										formik.setFieldValue("untilDate", new Date(item.selection.endDate))
									}
								}}
								moveRangeOnFirstSelection={false}
								ranges={[{ startDate: formik.values.sinceDate, endDate: formik.values.untilDate, key: "selection" }]}
								maxDate={new Date()}
							/>
						</PopoverBody>
					</PopoverContent>
				</Popover>
				<FormControl isInvalid={Boolean(formik.touched.workingHours && formik.errors.workingHours)}>
					<FormLabel fontWeight="bold">Working Hours</FormLabel>

					<NumberInput
						variant="filled"
						bgColor="grayscale.input-background"
						placeholder="Enter hours"
						_placeholder={{ color: "grayscale.placeholer" }}
						{...formik.getFieldProps("workingHours")}
						onChange={(_, valueAsNumber) => formik.setFieldValue("workingHours", valueAsNumber || 0)}
					>
						<NumberInputField />
						<NumberInputStepper>
							<NumberIncrementStepper />
							<NumberDecrementStepper />
						</NumberInputStepper>
					</NumberInput>

					<FormErrorMessage>{formik.errors.workingHours}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.userIds && formik.errors.userIds)}>
					<FormLabel fontWeight="bold">Users</FormLabel>

					<UsersSelector
						value={formik.values.userIds}
						onUpdate={(userIds, filter) => {
							formik.setFieldValue("userIds", userIds)
							formik.setFieldValue("usersFilter", filter)
						}}
					/>
					<Text fontSize="sm" color="error.400">
						{formik.errors.userIds}
					</Text>
				</FormControl>
				<Button type="submit" colorScheme="primary" isLoading={fetching}>
					Generate
				</Button>
			</VStack>
		</VStack>
	)
}
