import { Accordion, Badge, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { Bell, Box, File, FilePlus, Grid, HardDrive, Hexagon, Key, Layers, Link2, Map, Package, PieChart, Server, Shield, Smartphone, Tag, Triangle, Tv, User, Users } from "react-feather"
import { NavLink } from "react-router-dom"
import { Features, PermissionTags, useNewAlertSubscription } from "../../graphql"
import { useFeatures } from "../../hooks"
import { DashboardTypeSelector, Logo } from "../common"
import { AccountPopover } from "../common/AccountPopover"
import { SidebarAccordionItem } from "./SidebarAccordionItem"
import { SidebarButton } from "./SidebarButton"

export const Sidebar: React.FC = () => {
	const [newAlerts, setNewAlerts] = useState(new Set<string>())

	const [{ data: alertsData }] = useNewAlertSubscription()

	useEffect(() => {
		if (alertsData?.newAlert) {
			setNewAlerts((prev) => new Set([...prev, alertsData.newAlert._id]))
		}
	}, [alertsData])

	const { isFeatureEnabled } = useFeatures()

	return (
		<VStack pos="absolute" top="0" left="0" w="320px" h="100vh" align="stretch" justify="space-between" overflow="hidden" zIndex={10} p={4} backgroundColor="grayscale.background">
			<VStack h="full" align="stretch" overflow="auto" pb="4" spacing={8}>
				<NavLink to="/">
					<Logo />
				</NavLink>

				<DashboardTypeSelector />

				<VStack h="full" align="stretch" overflow="auto" spacing={8}>
					<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
						<SidebarButton to="/" permissionTags={[PermissionTags.ReadDisasterManagementDashboard, PermissionTags.ReadConfidentialityDashboard, PermissionTags.ReadAssetManagementDashboard]} permissionsType="or" icon={PieChart}>
							Dashboard
						</SidebarButton>

						<SidebarAccordionItem
							tos={["/users", "/users/create", "/users/types", "/users/types/create"]}
							permissionTags={[
								PermissionTags.ReadShortTermUser,
								PermissionTags.ReadWorkingUser,
								PermissionTags.ReadAdminUser,
								PermissionTags.CreateShortTermUser,
								PermissionTags.CreateWorkingUser,
								PermissionTags.CreateAdminUser,
							]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={User}>
									Users
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/users" permissionTags={[PermissionTags.ReadShortTermUser, PermissionTags.ReadWorkingUser, PermissionTags.ReadAdminUser]} permissionsType="or">
									Users
								</SidebarButton>
								<SidebarButton to="/users/create" permissionTags={[PermissionTags.CreateShortTermUser, PermissionTags.CreateWorkingUser, PermissionTags.CreateAdminUser]} permissionsType="or">
									Create User
								</SidebarButton>
								<SidebarAccordionItem
									tos={["/users/types", "/users/types/create"]}
									permissionTags={[
										PermissionTags.ReadShortTermUserType,
										PermissionTags.ReadWorkingUserType,
										PermissionTags.ReadAdminUserType,
										PermissionTags.CreateShortTermUserType,
										PermissionTags.CreateWorkingUserType,
										PermissionTags.CreateAdminUserType,
									]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Users}>
											User Types
										</SidebarButton>
									)}
								>
									<SidebarButton to="/users/types" permissionTags={[PermissionTags.ReadShortTermUserType, PermissionTags.ReadWorkingUserType, PermissionTags.ReadAdminUserType]} permissionsType="or">
										User Types
									</SidebarButton>
									<SidebarButton to="/users/types/create" permissionTags={[PermissionTags.CreateShortTermUserType, PermissionTags.CreateWorkingUserType, PermissionTags.CreateAdminUserType]} permissionsType="or">
										Create User Type
									</SidebarButton>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/users/groups", "/users/groups/create"]}
									permissionTags={[PermissionTags.ReadUserGroup, PermissionTags.CreateUserGroup]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Users}>
											User Groups
										</SidebarButton>
									)}
								>
									<SidebarButton to="/users/groups" permissionTags={[PermissionTags.ReadUserGroup]}>
										User Groups
									</SidebarButton>
									<SidebarButton to="/users/groups/create" permissionTags={[PermissionTags.CreateUserGroup]}>
										Create User Group
									</SidebarButton>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						{isFeatureEnabled(Features.Assets) && (
							<SidebarAccordionItem
								tos={["/assets", "/assets/create"]}
								permissionTags={[PermissionTags.ReadAsset, PermissionTags.CreateAsset]}
								permissionsType="or"
								button={(isActive) => (
									<SidebarButton isNested isActive={isActive} icon={Package}>
										Assets
									</SidebarButton>
								)}
							>
								<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
									<SidebarButton to="/assets" permissionTags={[PermissionTags.ReadAsset]} permissionsType="or">
										Assets
									</SidebarButton>
									<SidebarButton to="/assets/create" permissionTags={[PermissionTags.CreateAsset]} permissionsType="or">
										Create Asset
									</SidebarButton>
									<SidebarAccordionItem
										tos={["/assets/categories", "/assets/categories/create"]}
										permissionTags={[PermissionTags.CreateAssetCategory, PermissionTags.ReadAssetCategory]}
										permissionsType="or"
										button={(isActive) => (
											<SidebarButton icon={Layers} isActive={isActive} isNested>
												Asset Categories
											</SidebarButton>
										)}
									>
										<VStack h="full" align="stretch" overflow="auto" pb="4">
											<SidebarButton to="/assets/categories" permissionTags={[PermissionTags.CreateAssetCategory]}>
												Asset Categories
											</SidebarButton>
											<SidebarButton to="/assets/categories/create" permissionTags={[PermissionTags.ReadAssetCategory]}>
												Create Asset Category
											</SidebarButton>
										</VStack>
									</SidebarAccordionItem>
								</Accordion>
							</SidebarAccordionItem>
						)}

						<SidebarAccordionItem
							tos={["/zones", "/zones/create", "/zones/groups", "/zones/groups/create"]}
							permissionTags={[PermissionTags.ReadZone]}
							button={(isActive) => (
								<SidebarButton icon={Hexagon} isActive={isActive} isNested>
									Zones
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/zones" permissionTags={[PermissionTags.ReadZone]}>
									Zones
								</SidebarButton>
								<SidebarButton to="/zones/create" permissionTags={[PermissionTags.CreateZone]}>
									Create Zone
								</SidebarButton>
								<SidebarAccordionItem
									tos={["/zones/groups", "/zones/groups/create"]}
									permissionTags={[PermissionTags.ReadZoneGroup]}
									button={(isActive) => (
										<SidebarButton icon={Hexagon} isActive={isActive} isNested>
											Zone Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/zones/groups" permissionTags={[PermissionTags.ReadZoneGroup]}>
											Zone Groups
										</SidebarButton>
										<SidebarButton to="/zones/groups/create" permissionTags={[PermissionTags.CreateZoneGroup]}>
											Create Zone Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/zones/buildings", "/zones/buildings/create"]}
									permissionTags={[PermissionTags.ReadBuilding, PermissionTags.CreateBuilding]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton icon={Triangle} isActive={isActive} isNested>
											Buildings
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/zones/buildings" permissionTags={[PermissionTags.ReadBuilding]}>
											Buildings
										</SidebarButton>
										<SidebarButton to="/zones/buildings/create" permissionTags={[PermissionTags.CreateBuilding]}>
											Create Building
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/reports/past", "/reports/generate"]}
							permissionTags={[
								PermissionTags.CreateUserLocationRecordsReport,
								PermissionTags.CreateZoneLocationRecordsReport,
								PermissionTags.CreateZoneGroupLocationRecordsReport,
								PermissionTags.CreateBuildingLocationRecordsReport,
								PermissionTags.CreateUserAttendanceReport,
								PermissionTags.CreateDashboardReport,
								PermissionTags.CreateReaderStatusLogsReport,
								PermissionTags.CreateUserRestrictedEntryReport,
								PermissionTags.CreateZoneRestrictedEntryReport,
								PermissionTags.CreateZoneGroupRestrictedEntryReport,
								PermissionTags.CreateActiveUsersReport,
								PermissionTags.CreateVisitorReport,
								PermissionTags.CreateAssetLocationRecordsReport,
							]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={File}>
									Reports
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarButton to="/reports/past">Past</SidebarButton>
								<SidebarAccordionItem
									tos={[
										"/reports/generate/locationRecords/users",
										"/reports/generate/locationRecords/zones",
										"/reports/generate/locationRecords/zoneGroups",
										"/reports/generate/locationRecords/buildings",
										"/reports/generate/userAttendance",
										"/reports/generate/dashboard",
										"/reports/generate/readersStatusLogs",
										"/reports/generate/restrictedEntries/users",
										"/reports/generate/restrictedEntries/zones",
										"/reports/generate/restrictedEntries/zoneGroups",
										"/reports/generate/activeUsers",
										"/reports/generate/visitor",
										"/reports/generate/assetMovement",
									]}
									permissionTags={[
										PermissionTags.CreateUserLocationRecordsReport,
										PermissionTags.CreateZoneLocationRecordsReport,
										PermissionTags.CreateZoneGroupLocationRecordsReport,
										PermissionTags.CreateBuildingLocationRecordsReport,
										PermissionTags.CreateUserAttendanceReport,
										PermissionTags.CreateDashboardReport,
										PermissionTags.CreateReaderStatusLogsReport,
										PermissionTags.CreateUserRestrictedEntryReport,
										PermissionTags.CreateZoneRestrictedEntryReport,
										PermissionTags.CreateZoneGroupRestrictedEntryReport,
										PermissionTags.CreateActiveUsersReport,
										PermissionTags.CreateVisitorReport,
										PermissionTags.CreateAssetLocationRecordsReport,
									]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={FilePlus}>
											Generate
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										{isFeatureEnabled(Features.DisasterManagement) && (
											<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
												<SidebarAccordionItem
													tos={["/reports/generate/locationRecords/users", "/reports/generate/locationRecords/zones", "/reports/generate/locationRecords/zoneGroups", "/reports/generate/locationRecords/buildings"]}
													permissionTags={[
														PermissionTags.CreateUserLocationRecordsReport,
														PermissionTags.CreateZoneLocationRecordsReport,
														PermissionTags.CreateZoneGroupLocationRecordsReport,
														PermissionTags.CreateBuildingLocationRecordsReport,
													]}
													permissionsType="or"
													button={(isActive) => (
														<SidebarButton isNested isActive={isActive}>
															Location Records
														</SidebarButton>
													)}
												>
													<VStack h="full" align="stretch" overflow="auto" pb="4">
														<SidebarButton to="/reports/generate/locationRecords/users" permissionTags={[PermissionTags.CreateUserLocationRecordsReport]}>
															User
														</SidebarButton>
														<SidebarButton to="/reports/generate/locationRecords/zones" permissionTags={[PermissionTags.CreateZoneLocationRecordsReport]}>
															Zones
														</SidebarButton>
														<SidebarButton to="/reports/generate/locationRecords/zoneGroups" permissionTags={[PermissionTags.CreateZoneGroupLocationRecordsReport]}>
															Zone Groups
														</SidebarButton>
														<SidebarButton to="/reports/generate/locationRecords/buildings" permissionTags={[PermissionTags.CreateBuildingLocationRecordsReport]}>
															Buildings
														</SidebarButton>
													</VStack>
												</SidebarAccordionItem>
											</Accordion>
										)}
										{isFeatureEnabled(Features.DisasterManagement) && (
											<SidebarButton to="/reports/generate/userAttendance" permissionTags={[PermissionTags.CreateUserAttendanceReport]}>
												User Attendance
											</SidebarButton>
										)}
										{isFeatureEnabled(Features.DisasterManagement) && (
											<SidebarButton to="/reports/generate/dashboard" permissionTags={[PermissionTags.CreateDashboardReport]}>
												Dashboard
											</SidebarButton>
										)}
										{isFeatureEnabled(Features.DisasterManagement) && (
											<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
												<SidebarAccordionItem
													tos={["/reports/generate/restrictedEntries/users", "/reports/generate/restrictedEntries/zones", "/reports/generate/restrictedEntries/zoneGroups"]}
													permissionTags={[PermissionTags.CreateActiveUsersReport]}
													button={(isActive) => (
														<SidebarButton isNested isActive={isActive}>
															Active Users
														</SidebarButton>
													)}
												>
													<VStack h="full" align="stretch" overflow="auto" pb="4">
														<SidebarButton to="/reports/generate/activeUsersAtZones" permissionTags={[PermissionTags.CreateActiveUsersReport]}>
															Zones
														</SidebarButton>
														<SidebarButton to="/reports/generate/activeUsersAtZoneGroups" permissionTags={[PermissionTags.CreateActiveUsersReport]}>
															Zone Groups
														</SidebarButton>
														<SidebarButton to="/reports/generate/activeUsersAtBuildings" permissionTags={[PermissionTags.CreateActiveUsersReport]}>
															Buildings
														</SidebarButton>
													</VStack>
												</SidebarAccordionItem>
											</Accordion>
										)}
										{isFeatureEnabled(Features.DisasterManagement) && (
											<SidebarButton to="/reports/generate/visitor" permissionTags={[PermissionTags.CreateVisitorReport]}>
												Visitor
											</SidebarButton>
										)}
										<SidebarButton to="/reports/generate/assetMovement" permissionTags={[PermissionTags.CreateAssetLocationRecordsReport]}>
											Asset Movement
										</SidebarButton>
										<SidebarButton to="/reports/generate/readersStatusLogs" permissionTags={[PermissionTags.CreateReaderStatusLogsReport]}>
											Readers Status Logs
										</SidebarButton>
										{isFeatureEnabled(Features.DisasterManagement) && (
											<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
												<SidebarAccordionItem
													tos={["/reports/generate/restrictedEntries/users", "/reports/generate/restrictedEntries/zones", "/reports/generate/restrictedEntries/zoneGroups"]}
													permissionTags={[PermissionTags.CreateUserRestrictedEntryReport, PermissionTags.CreateZoneRestrictedEntryReport, PermissionTags.CreateZoneGroupRestrictedEntryReport]}
													permissionsType="or"
													button={(isActive) => (
														<SidebarButton isNested isActive={isActive}>
															Restricted Entries
														</SidebarButton>
													)}
												>
													<VStack h="full" align="stretch" overflow="auto" pb="4">
														<SidebarButton to="/reports/generate/restrictedEntries/users" permissionTags={[PermissionTags.CreateUserRestrictedEntryReport]}>
															Users
														</SidebarButton>
														<SidebarButton to="/reports/generate/restrictedEntries/zones" permissionTags={[PermissionTags.CreateZoneRestrictedEntryReport]}>
															Zones
														</SidebarButton>
														<SidebarButton to="/reports/generate/restrictedEntries/zoneGroups" permissionTags={[PermissionTags.CreateZoneGroupRestrictedEntryReport]}>
															Zone Groups
														</SidebarButton>
													</VStack>
												</SidebarAccordionItem>
											</Accordion>
										)}
									</VStack>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/alerts", "/alerts/flows", "/alerts/flows/create", "/alerts/targetGroups", "/alerts/targetGroups/create"]}
							permissionTags={[PermissionTags.ReadAlert, PermissionTags.ReadAlertFlow, PermissionTags.CreateAlertFlow, PermissionTags.ReadAlertTargetGroup, PermissionTags.CreateAlertTargetGroup]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton
									isNested
									isActive={isActive}
									icon={Bell}
									rightIcon={
										newAlerts.size ? (
											<Badge variant="solid" rounded="full" colorScheme="warning">
												{newAlerts.size}
											</Badge>
										) : undefined
									}
								>
									Alerts
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<VStack h="full" align="stretch" overflow="auto" pb="4">
									<SidebarButton
										to="/alerts"
										rightIcon={
											newAlerts.size ? (
												<Badge variant="solid" rounded="full" colorScheme="warning">
													{newAlerts.size}
												</Badge>
											) : undefined
										}
									>
										Alerts
									</SidebarButton>
								</VStack>
								<SidebarAccordionItem
									tos={["/alerts/flows", "/alerts/flows/create"]}
									permissionTags={[PermissionTags.ReadAlertFlow, PermissionTags.CreateAlertFlow]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Link2}>
											Alert Flows
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/alerts/flows" permissionTags={[PermissionTags.ReadAlertFlow]}>
											Alert Flows
										</SidebarButton>
										<SidebarButton to="/alerts/flows/create" permissionTags={[PermissionTags.CreateAlertFlow]}>
											Create Alert Flow
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/alerts/targetGroups", "/alerts/targetGroups/create"]}
									permissionTags={[PermissionTags.ReadAlertTargetGroup, PermissionTags.CreateAlertTargetGroup]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Users}>
											Alert Target Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/alerts/targetGroups" permissionTags={[PermissionTags.ReadAlertTargetGroup]}>
											Alert Target Groups
										</SidebarButton>
										<SidebarButton to="/alerts/targetGroups/create" permissionTags={[PermissionTags.CreateAlertTargetGroup]}>
											Create Alert Target Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
							</Accordion>
						</SidebarAccordionItem>

						<SidebarAccordionItem
							tos={["/departments", "/departments/create", "/permissionsGroups", "/permissionsGroups/create", "/userRoleGroups", "/userRoleGroups/create"]}
							permissionTags={[
								PermissionTags.ReadDepartment,
								PermissionTags.CreateDepartment,
								PermissionTags.ReadPermissionsTags,
								PermissionTags.ReadPermissionsGroup,
								PermissionTags.CreatePermissionsGroup,
								PermissionTags.ReadShortTermRoleGroup,
								PermissionTags.ReadWorkingRoleGroup,
								PermissionTags.CreateShortTermRoleGroup,
								PermissionTags.CreateWorkingRoleGroup,
								PermissionTags.ReadReader,
								PermissionTags.CreateReader,
								PermissionTags.ReadDevice,
								PermissionTags.CreateDevice,
								PermissionTags.ReadTag,
								PermissionTags.CreateTag,
								PermissionTags.ReadService,
								PermissionTags.CreateService,
							]}
							permissionsType="or"
							button={(isActive) => (
								<SidebarButton isNested isActive={isActive} icon={Box}>
									More
								</SidebarButton>
							)}
						>
							<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
								<SidebarAccordionItem
									tos={["/departments", "/departments/create"]}
									permissionTags={[PermissionTags.ReadDepartment, PermissionTags.CreateDepartment]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton icon={Grid} isActive={isActive} isNested>
											Departments
										</SidebarButton>
									)}
								>
									<Accordion as={VStack} w="full" align="stretch" allowToggle allowMultiple={false} borderColor="transparent">
										<SidebarButton to="/departments" permissionTags={[PermissionTags.ReadDepartment]}>
											Departments
										</SidebarButton>
										<SidebarButton to="/departments/create" permissionTags={[PermissionTags.CreateDepartment]}>
											Create Department
										</SidebarButton>
									</Accordion>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/permissionsGroups", "/permissionsGroups/create"]}
									permissionTags={[PermissionTags.ReadPermissionsTags, PermissionTags.ReadPermissionsGroup, PermissionTags.CreatePermissionsGroup]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Key}>
											Permissions Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/permissionsGroups" permissionTags={[PermissionTags.ReadPermissionsTags, PermissionTags.ReadPermissionsGroup]}>
											Permissions Groups
										</SidebarButton>
										<SidebarButton to="/permissionsGroups/create" permissionTags={[PermissionTags.ReadPermissionsTags, PermissionTags.ReadPermissionsGroup, PermissionTags.CreatePermissionsGroup]}>
											Create Permissions Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/userRoleGroups", "/userRoleGroups/create"]}
									permissionTags={[PermissionTags.ReadShortTermRoleGroup, PermissionTags.ReadWorkingRoleGroup, PermissionTags.CreateShortTermRoleGroup, PermissionTags.CreateWorkingRoleGroup]}
									permissionsType="or"
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Shield}>
											User Role Groups
										</SidebarButton>
									)}
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/userRoleGroups" permissionTags={[PermissionTags.ReadShortTermRoleGroup, PermissionTags.ReadWorkingRoleGroup]} permissionsType="or">
											User Role Groups
										</SidebarButton>
										<SidebarButton to="/userRoleGroups/create" permissionTags={[PermissionTags.CreateShortTermRoleGroup, PermissionTags.CreateWorkingRoleGroup]} permissionsType="or">
											Create User Role Group
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/readers", "/readers/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={HardDrive}>
											Readers
										</SidebarButton>
									)}
									permissionTags={[PermissionTags.ReadReader, PermissionTags.CreateReader]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/readers" permissionTags={[PermissionTags.ReadReader]}>
											Readers
										</SidebarButton>
										<SidebarButton to="/readers/create" permissionTags={[PermissionTags.CreateReader]}>
											Create Reader
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/tags", "/tags/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Tag}>
											Tags
										</SidebarButton>
									)}
									permissionTags={[PermissionTags.ReadTag, PermissionTags.CreateTag]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/tags" permissionTags={[PermissionTags.ReadTag]}>
											Tags
										</SidebarButton>
										<SidebarButton to="/tags/create" permissionTags={[PermissionTags.CreateTag]}>
											Create Tag
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/devices", "/devices/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Smartphone}>
											Devices
										</SidebarButton>
									)}
									permissionTags={[PermissionTags.ReadDevice, PermissionTags.CreateDevice]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/devices" permissionTags={[PermissionTags.ReadDevice]}>
											Devices
										</SidebarButton>
										<SidebarButton to="/devices/create" permissionTags={[PermissionTags.CreateDevice]}>
											Create Device
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/services", "/services/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Server}>
											Services
										</SidebarButton>
									)}
									permissionTags={[PermissionTags.ReadService, PermissionTags.CreateService]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/services" permissionTags={[PermissionTags.ReadService]}>
											Services
										</SidebarButton>
										<SidebarButton to="/services/create" permissionTags={[PermissionTags.CreateService]}>
											Create Service
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarAccordionItem
									tos={["/tiles", "/tiles/create"]}
									button={(isActive) => (
										<SidebarButton isNested isActive={isActive} icon={Map}>
											Tiles
										</SidebarButton>
									)}
									permissionTags={[PermissionTags.ReadTile, PermissionTags.CreateTile]}
									permissionsType="or"
								>
									<VStack h="full" align="stretch" overflow="auto" pb="4">
										<SidebarButton to="/tiles" permissionTags={[PermissionTags.ReadTile]}>
											Tiles
										</SidebarButton>
										<SidebarButton to="/tiles/create" permissionTags={[PermissionTags.CreateTile]}>
											Create Tile
										</SidebarButton>
									</VStack>
								</SidebarAccordionItem>
								<SidebarButton to="/tv" permissionTags={[PermissionTags.ReadTvScreen]} icon={Tv}>
									TV
								</SidebarButton>
							</Accordion>
						</SidebarAccordionItem>
					</Accordion>
				</VStack>
			</VStack>
			<AccountPopover />
		</VStack>
	)
}
