import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import { startCase } from "lodash"
import React from "react"
import { NavLink } from "react-router-dom"
import { DeviceFragment } from "../../graphql"
import { Stat } from "../common"

export type DeviceListItemProps = {
	device: DeviceFragment
}

export const DeviceListItem: React.FC<DeviceListItemProps> = ({ device }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/devices/${device._id}`} />

			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={device.label.name} />
				<Stat label="UID" value={device.deviceId} />
				<Stat label="Type" value={startCase(device.type)} />
				<Stat label="Last updated at" value={format(new Date(device.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(device.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
