/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, InputGroup, Select, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import DatePicker from "react-date-picker"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { CreateUserMutationVariables, EmailInput, PhoneInput, useCreateUserMutation, UserRoleTypes, useUserTypesQuery, useZoneGroupsQuery, VisitorDetailsInput } from "../graphql"
import { startOfDay } from "date-fns"

type CreateUserFormValues = Omit<CreateUserMutationVariables["input"], "email" | "phone" | "visitorDetails"> & { email?: EmailInput; phone?: PhoneInput; visitorDetails?: VisitorDetailsInput }

const validationSchema = yup.object<Record<keyof CreateUserFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	name: yup.string().required().label("Name"),
	email: yup
		.object({
			address: yup.string().nullable().label("Address"),
		})
		.nullable()
		.label("Email"),
	phone: yup
		.object({
			number: yup.string().nullable().label("Number"),
			countryCode: yup.string().nullable().label("Country Code"),
		})
		.nullable()
		.label("Phone"),
	address: yup.string().nullable().label("Address"),
	employeeId: yup.string().nullable().label("Employee ID"),
	typeId: yup.string().required().label("Type"),
	visitorDetails: yup
		.object({
			visitDate: yup.date().required().label("Visit Date"),
			companyName: yup.string().nullable().label("Company Name"),
			level: yup.string().nullable().label("Level"),
			visitorCardNo: yup.string().nullable().label("Visitor Card Number"),
			buildingName: yup.string().nullable().label("Building Name"),
		})
		.nullable()
		.label("Visitor Details"),
})

const initialValues: CreateUserFormValues = {
	name: "",
	typeId: "",
	email: undefined,
	phone: {
		countryCode: "+91",
		number: "",
	},
	address: "",
	employeeId: "",
	visitorDetails: {
		visitDate: startOfDay(new Date()),
		companyName: "",
		level: "",
		visitorCardNo: "",
		buildingName: "",
	},
}

export const CreateUserForm: React.FC = () => {
	const [{ fetching }, createUser] = useCreateUserMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: CreateUserFormValues) => {
		const { data, error } = await createUser({
			input: {
				...values,
				email: values.email?.address ? values.email : undefined,
				phone: values.phone?.number ? values.phone : undefined,
				visitorDetails: values.typeId === userTypesData?.userTypes.find((userType) => userType.label.name === "Visitor")?._id ? values.visitorDetails : undefined,
			},
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.createUser) {
			navigate(`/users/${data.createUser._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<CreateUserFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: userTypesData, error: userTypesError, fetching: userTypesFetching }] = useUserTypesQuery()

	const [{ data: zoneGroupsData, error: zoneGroupsError, fetching: zoneGroupsFetching }] = useZoneGroupsQuery()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch">
				<FormControl isInvalid={Boolean(formik.touched.name && formik.errors.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("name")} />

					<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.typeId && formik.errors.typeId)} isRequired>
					<FormLabel fontWeight="bold">Type</FormLabel>

					{userTypesFetching ? (
						<Text>Fetching types</Text>
					) : userTypesError ? (
						<VStack>
							<Text>Couldn&apos;t fetch types</Text>
							<Text>{userTypesError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !userTypesData?.userTypes.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch types</Text>
						</VStack>
					) : (
						<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Type" {...formik.getFieldProps("typeId")}>
							{userTypesData.userTypes.map((type) => (
								<option key={type._id} style={{ backgroundColor: "transparent" }} value={type._id}>
									{type.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.typeId}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean((formik.touched.email as unknown as EmailInput)?.address && (formik.errors.email as unknown as EmailInput)?.address)}>
					<FormLabel fontWeight="bold">Email</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter email" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("email.address")} />

					<FormErrorMessage>{(formik.errors.email as unknown as EmailInput)?.address}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean((formik.touched.phone as unknown as PhoneInput)?.number && (formik.errors.phone as unknown as PhoneInput)?.number)}>
					<FormLabel fontWeight="bold">Phone</FormLabel>

					<InputGroup as={HStack} spacing={1}>
						<Select flex="3" variant="filled" bgColor="grayscale.input-background" roundedRight="none" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("phone.countryCode")}>
							<option value="+91">+91</option>
						</Select>

						<Input flex="7" variant="filled" bgColor="grayscale.input-background" roundedLeft="none" placeholder="Enter phone" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("phone.number")} />
					</InputGroup>
					<FormErrorMessage>{(formik.errors.phone as unknown as PhoneInput)?.number}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={Boolean(formik.touched.address && formik.errors.address)}>
					<FormLabel fontWeight="bold">Address</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter address" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("address")} />

					<FormErrorMessage>{formik.errors.address}</FormErrorMessage>
				</FormControl>

				{userTypesData?.userTypes.find((t) => t._id === formik.values.typeId)?.roleType === UserRoleTypes.Working && (
					<FormControl isInvalid={Boolean(formik.touched.employeeId && formik.errors.employeeId)}>
						<FormLabel fontWeight="bold">Employee ID</FormLabel>

						<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter employee ID" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("employeeId")} />

						<FormErrorMessage>{formik.errors.employeeId}</FormErrorMessage>
					</FormControl>
				)}

				{userTypesData?.userTypes.find((t) => t._id === formik.values.typeId)?.label.name === "Visitor" && (
					<VStack w="full" align="stretch">
						<FormControl isInvalid={Boolean((formik.touched.visitorDetails as unknown as VisitorDetailsInput)?.companyName && (formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.companyName)}>
							<FormLabel fontWeight="bold">Visitor Company Name</FormLabel>

							<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter visitor company name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("visitorDetails.companyName")} />

							<FormErrorMessage>{(formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.companyName}</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={Boolean((formik.touched.visitorDetails as unknown as VisitorDetailsInput)?.level && (formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.level)}>
							<FormLabel fontWeight="bold">Level</FormLabel>

							{zoneGroupsFetching ? (
								<Text>Fetching zone groups</Text>
							) : zoneGroupsError ? (
								<VStack>
									<Text>Couldn&apos;t fetch zone groups</Text>
									<Text>{zoneGroupsError.message.replace("[GraphQL] ", "")}</Text>
								</VStack>
							) : !zoneGroupsData?.zoneGroups.length ? (
								<VStack>
									<Text>Couldn&apos;t fetch zone groups</Text>
								</VStack>
							) : (
								<Select variant="filled" bgColor="grayscale.input-background" placeholder="Select Level" {...formik.getFieldProps("visitorDetails.level")}>
									{zoneGroupsData.zoneGroups.map((zoneGroup) => (
										<option key={zoneGroup._id} style={{ backgroundColor: "transparent" }} value={zoneGroup.label.name}>
											{zoneGroup.label.name}
										</option>
									))}
								</Select>
							)}

							<FormErrorMessage>{(formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.level}</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={Boolean((formik.touched.visitorDetails as unknown as VisitorDetailsInput)?.visitorCardNo && (formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.visitorCardNo)}>
							<FormLabel fontWeight="bold">Visitor Card Number</FormLabel>

							<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter visitor card number" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("visitorDetails.visitorCardNo")} />

							<FormErrorMessage>{(formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.visitorCardNo}</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={Boolean((formik.touched.visitorDetails as unknown as VisitorDetailsInput)?.buildingName && (formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.buildingName)}>
							<FormLabel fontWeight="bold">Building</FormLabel>

							<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter building name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("visitorDetails.buildingName")} />

							<FormErrorMessage>{(formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.buildingName}</FormErrorMessage>
						</FormControl>

						<FormControl isInvalid={Boolean((formik.touched.visitorDetails as unknown as VisitorDetailsInput)?.visitDate && (formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.visitDate)} pt="2">
							<DatePicker
								onChange={(date) => formik.setFieldValue("visitorDetails.visitDate", date)}
								value={formik.values.visitorDetails && formik.values.visitorDetails.visitDate ? formik.values.visitorDetails.visitDate : new Date()}
								format="dd/MM/yyyy"
								maxDate={new Date()}
								clearIcon={null}
							/>
							<FormErrorMessage>{(formik.errors.visitorDetails as unknown as VisitorDetailsInput)?.visitDate}</FormErrorMessage>
						</FormControl>
					</VStack>
				)}
			</VStack>
			<Button type="submit" colorScheme="primary" isLoading={fetching}>
				Create
			</Button>
		</VStack>
	)
}
