import { Flex, ListItem } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React from "react"
import { ReaderStatusLogFragment } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Stat } from "../common"

export type ReaderStatusLogListItemProps = {
	log: ReaderStatusLogFragment
}

export const ReaderStatusLogListItem: React.FC<ReaderStatusLogListItemProps> = ({ log }) => {
	return (
		<ListItem>
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Connected at" value={format(new Date(log.connectedAt), "MMM dd, yyyy p")} />
				{log.disconnectedAt && <Stat label="Disconnected at" value={format(new Date(log.disconnectedAt), "MMM dd, yyyy p")} />}
				<Stat label="Duration" value={getDurationInWords(differenceInSeconds(log.disconnectedAt ? new Date(log.disconnectedAt) : new Date(), new Date(log.connectedAt)))} />
			</Flex>
		</ListItem>
	)
}
