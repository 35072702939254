import { Button, Flex, Heading, HStack, Icon, Link, Modal, ModalBody, ModalContent, ModalOverlay, Spinner, Text, useToast, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React, { useEffect, useMemo, useState } from "react"
import { AlertCircle } from "react-feather"
import { NavLink } from "react-router-dom"
import { AlertTypes, SosRecordStatus, useActiveSosRecordIdsQuery, useNewAlertSubscription, useSosRecordByIdQuery, useSosRecordsUpdateSubscription, useUserByIdQuery, useUserLatestLocationRecordQuery, useZoneByIdQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { UserTag } from "../common"
import { Stat } from "../common/Stat"

type SosModalProps = {
	playSound: () => void
	pauseSound: () => void
	isSoundPlaying: boolean
	hasUserInteracted: boolean
}

// eslint-disable-next-line react/display-name, react/prop-types
export const SosModal: React.FC<SosModalProps> = React.memo(({ playSound, pauseSound, isSoundPlaying, hasUserInteracted }) => {
	const [sosRecords, setSosRecords] = useState<{ sosRecordId: string; isHidden: boolean }[]>([])

	const [{ data: activeSosRecordsData }] = useActiveSosRecordIdsQuery()

	useEffect(() => {
		if (activeSosRecordsData?.activeSosRecordIds.length) {
			for (const record of activeSosRecordsData.activeSosRecordIds) {
				if (!sosRecords.find((r) => r.sosRecordId === record)) {
					setSosRecords((prev) => [{ sosRecordId: record, isHidden: false }, ...prev])
				}
			}
		}
	}, [activeSosRecordsData])

	const [{ data }] = useNewAlertSubscription()

	const recordIds = useMemo(() => sosRecords.map((a) => a.sosRecordId).filter(Boolean), [sosRecords])

	const [{ data: sosData }] = useSosRecordsUpdateSubscription({ variables: { recordIds }, pause: !recordIds.length })

	useEffect(() => {
		if (data?.newAlert.type === AlertTypes.Sos && typeof data.newAlert.sosRecordId === "string" && !sosRecords.find((r) => r.sosRecordId === data.newAlert.sosRecordId)) {
			setSosRecords((prev) => [{ sosRecordId: data.newAlert.sosRecordId || "", isHidden: false }, ...(prev || [])])
		}
	}, [data])

	useEffect(() => {
		if (sosData?.sosRecordsUpdate && sosData.sosRecordsUpdate.status === SosRecordStatus.Final && sosRecords.find((r) => r.sosRecordId === sosData.sosRecordsUpdate._id)) {
			const _records = [...sosRecords]
			_records.splice(
				sosRecords.findIndex((r) => r.sosRecordId === sosData.sosRecordsUpdate._id),
				1
			)

			setSosRecords(_records)
		}
	}, [sosData])

	const openSosRecords = useMemo(() => sosRecords.filter(({ isHidden }) => !isHidden), [sosRecords])
	const hiddenSosRecords = useMemo(() => sosRecords.filter(({ isHidden }) => isHidden), [sosRecords])

	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		if (openSosRecords.length && !isOpen) {
			setIsOpen(true)
		} else if (!openSosRecords.length && isOpen) {
			setIsOpen(false)
		}
	}, [openSosRecords, isOpen])

	useEffect(() => {
		if (openSosRecords.length > 0 && isOpen) {
			if (!isSoundPlaying) {
				playSound()
			}
		} else if (isSoundPlaying) {
			pauseSound()
		}
	}, [openSosRecords, isOpen, isSoundPlaying, hasUserInteracted])

	const hideAll = (sosRecordIds: string[]) => {
		setSosRecords((prev) => prev.map((r) => (sosRecordIds.includes(r.sosRecordId) ? { ...r, isHidden: true } : r)))
		pauseSound()
	}

	const toast = useToast()

	useEffect(() => {
		if (hiddenSosRecords.length && !isOpen) {
			if (toast.isActive("hidden-sos")) toast.close("hidden-sos")

			toast({
				id: "hidden-sos",
				position: "top-right",
				duration: null,
				isClosable: false,
				render: () => (
					<HStack w="full" align="stretch" p="4" bgColor="rgba(255,0,0, 0.2)" backdropFilter="blur(8px)" alignItems="center" rounded="xl" spacing={4}>
						<Icon as={AlertCircle} color="red.600" fontSize="xl" />
						<VStack w="full" align="flex-start">
							<Heading color="red.600" fontSize="sm">
								{hiddenSosRecords.length} SOS record{hiddenSosRecords.length > 1 ? "s are" : " is"} hidden.
							</Heading>

							<Button variant="outline" fontSize="sm" colorScheme="red" size="sm" onClick={() => setSosRecords((prev) => prev.map((o) => ({ ...o, isHidden: false })))}>
								Show
							</Button>
						</VStack>
					</HStack>
				),
			})
		} else if (isOpen) {
			toast.close("hidden-sos")
		}
	}, [isOpen, hiddenSosRecords])

	return (
		<Modal size="2xl" isCentered isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnOverlayClick={false} closeOnEsc={false}>
			<ModalOverlay bgColor="rgba(255,0,0,0.2)">
				<ModalContent>
					<ModalBody>
						<VStack w="full" p="4">
							<Heading fontSize="lg" color="error.800">
								SOS
							</Heading>

							<VStack w="full" align="stretch" maxH="xs" p="2" overflow="auto">
								{sosRecords.filter(({ isHidden }) => !isHidden).map((r) => (r.sosRecordId ? <Item key={r.sosRecordId} sosRecordId={r.sosRecordId} /> : <></>))}
							</VStack>

							<Button colorScheme="error" size="sm" onClick={() => hideAll(sosRecords.filter((r) => !r.isHidden).map((r) => r.sosRecordId))}>
								Hide
							</Button>
						</VStack>
					</ModalBody>
				</ModalContent>
			</ModalOverlay>
		</Modal>
	)
})

// eslint-disable-next-line react/display-name, react/prop-types
const Item: React.FC<{ sosRecordId: string }> = React.memo(({ sosRecordId }) => {
	const [{ data: sosRecordData, fetching: fetchingUserLocationRecord, error: sosRecordError }] = useSosRecordByIdQuery({ variables: { sosRecordId } })

	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: sosRecordData?.sosRecordById?.userId || "" }, pause: !sosRecordData?.sosRecordById?.userId })
	const [{ data: userLocationData, fetching: fetchingUserLocation, error: userLocationError }] = useUserLatestLocationRecordQuery({
		variables: { userId: sosRecordData?.sosRecordById?.userId || "" },
		pause: !sosRecordData?.sosRecordById?.userId,
	})
	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({ variables: { zoneId: userLocationData?.userLatestLocationRecord?.zoneId || "" }, pause: !userLocationData?.userLatestLocationRecord?.zoneId })

	if (fetchingUserLocationRecord) return <Spinner />

	if (sosRecordError) return <Text color="error.500">{sosRecordError.message.replace("[GraphQL] ", "")}</Text>

	if (!sosRecordData?.sosRecordById) return <Text color="error.500">No record</Text>

	return (
		<Flex flexWrap="wrap">
			<Stat
				label="User"
				value={
					fetchingUser ? (
						<Spinner size="sm" />
					) : userError ? (
						<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
					) : !userData?.userById ? (
						<Text color="error.500">No user</Text>
					) : (
						<Link as={NavLink} to={`/users/${sosRecordData.sosRecordById.userId}`}>
							<UserTag user={userData.userById} />
						</Link>
					)
				}
			/>
			<Stat
				label="Zone"
				value={
					fetchingUserLocation || fetchingZone ? (
						<Spinner size="sm" />
					) : userLocationError || zoneError ? (
						<Text color="error.500">{(userLocationError || zoneError)!.message.replace("[GraphQL] ", "")}</Text>
					) : !userLocationData?.userLatestLocationRecord || !zoneData?.zoneById ? (
						<Text color="error.500">Not found</Text>
					) : (
						<Link as={NavLink} to={`/zones/${userLocationData.userLatestLocationRecord.zoneId}`}>
							{zoneData.zoneById.label.name}
						</Link>
					)
				}
			/>
			{userLocationData?.userLatestLocationRecord && <Stat label="Last seen" value={`${getDurationInWords(differenceInSeconds(new Date(), new Date(userLocationData.userLatestLocationRecord.endAt)))} ago`} />}
			<Stat label="Since" value={format(new Date(sosRecordData.sosRecordById.startAt), "MMM dd, yyyy p")} />
			<Stat label="Until" value={format(new Date(sosRecordData.sosRecordById.endAt), "MMM dd, yyyy p")} />
			<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(sosRecordData.sosRecordById.endAt), new Date(sosRecordData.sosRecordById.startAt)))} />
		</Flex>
	)
})
