import { Center, Flex, Icon, Link, Spinner, Tag, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds } from "date-fns"
import React from "react"
import { XCircle } from "react-feather"
import { NavLink } from "react-router-dom"
import { AssetLocationRecordStatus, useAssetCurrentLocationRecordQuery, useZoneByIdQuery } from "../../graphql"
import { getDurationInWords, transformDateTime } from "../../utils"
import { Card, Stat } from "../common"

export type AssetCurrentLocationProps = {
	assetId: string
}

export const AssetCurrentLocation: React.FC<AssetCurrentLocationProps> = ({ assetId }) => {
	const [{ data: assetData, fetching: fetchingAsset, error: assetError }] = useAssetCurrentLocationRecordQuery({ variables: { assetId } })
	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({ variables: { zoneId: assetData?.assetCurrentLocationRecord?.zoneId ?? "" }, pause: !assetData?.assetCurrentLocationRecord?.zoneId })

	return (
		<Card title="Current Location">
			{fetchingAsset ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading current location</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : assetError ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{assetError.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : assetData?.assetCurrentLocationRecord && assetData.assetCurrentLocationRecord.status !== AssetLocationRecordStatus.Final ? (
				<VStack w="full" align="flex-start" p={4}>
					{assetData.assetCurrentLocationRecord.isRestricted && (
						<Tag colorScheme="error" variant="solid">
							Restricted <Icon as={XCircle} ml="2" />
						</Tag>
					)}
					<Flex align="flex-start" flexWrap="wrap" gap={4}>
						<Stat
							label="Zone"
							value={
								fetchingZone ? (
									<Spinner size="sm" />
								) : zoneError ? (
									<Text color="error.500">{zoneError.message.replace("[GraphQL] ", "")}</Text>
								) : !zoneData?.zoneById ? (
									<Text color="error.500">No zone</Text>
								) : (
									<Link as={NavLink} to={`/zones/${assetData.assetCurrentLocationRecord.zoneId}`}>
										{zoneData.zoneById.label.name}
									</Link>
								)
							}
						/>
						<Stat label="Since" value={transformDateTime(new Date(assetData.assetCurrentLocationRecord.startAt))} />
						<Stat label="Until" value={transformDateTime(new Date(assetData.assetCurrentLocationRecord.endAt))} />
						<Stat label="Status" value={assetData.assetCurrentLocationRecord.status} />
						<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(assetData.assetCurrentLocationRecord.endAt), new Date(assetData.assetCurrentLocationRecord.startAt)))} />
					</Flex>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find current location
					</Text>
				</Center>
			)}
		</Card>
	)
}
