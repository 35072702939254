import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertFlowFragment } from "../../graphql"
import { Stat } from "../common"

export type AlertFlowListItemProps = {
	alertFlow: AlertFlowFragment
}

export const AlertFlowListItem: React.FC<AlertFlowListItemProps> = ({ alertFlow }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/alerts/flows/${alertFlow._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={alertFlow.label.name} />
				<Stat label="Type" value={startCase(alertFlow.type)} />
				<Stat label="Last updated at" value={format(new Date(alertFlow.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(alertFlow.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
