import { Flex } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { UserGroupFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type UserGroupDetailsProps = {
	userGroup: UserGroupFragment
}

export const UserGroupDetails: React.FC<UserGroupDetailsProps> = ({ userGroup }) => {
	return (
		<Card title="Details">
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Users" value={userGroup.userIds.length || 0} />
				<Stat label="Last updated at" value={format(new Date(userGroup.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(userGroup.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
