import { Center, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds, format } from "date-fns"
import React from "react"
import { SosRecordStatus, useActiveSosRecordOfUserQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Card, Stat } from "../common"

export type UserActiveSosRecordProps = {
	userId: string
}

export const UserActiveSosRecord: React.FC<UserActiveSosRecordProps> = ({ userId }) => {
	const [{ data, fetching, error }] = useActiveSosRecordOfUserQuery({ variables: { userId } })

	return (
		<Card title="Active SOS">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading active SOS record</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeSosRecordOfUser ? (
				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					<Stat label="Since" value={format(new Date(data.activeSosRecordOfUser.startAt), "MMM dd, yyyy p")} />
					{data.activeSosRecordOfUser.status === SosRecordStatus.Final ? <Stat label="Until" value={format(new Date(data.activeSosRecordOfUser.endAt), "MMM dd, yyyy p")} /> : <></>}
					<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(data.activeSosRecordOfUser.endAt), new Date(data.activeSosRecordOfUser.startAt)))} />
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find active SOS record
					</Text>
				</Center>
			)}
		</Card>
	)
}
