import { Flex } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { PermissionsGroupFragment } from "../../graphql"
import { Card, Stat } from "../common"

export type PermissionsGroupDetailsProps = {
	permissionsGroup: PermissionsGroupFragment
}

export const PermissionsGroupDetails: React.FC<PermissionsGroupDetailsProps> = ({ permissionsGroup }) => {
	return (
		<Card title="Details">
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Permissions" value={permissionsGroup.tags.length || 0} />
				<Stat label="Last updated at" value={format(new Date(permissionsGroup.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(permissionsGroup.createdAt), "MMM dd, yyyy p")} />{" "}
			</Flex>
		</Card>
	)
}
