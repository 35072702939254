import { Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import startCase from "lodash/startCase"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ZoneGroupsSelector, ZoneSelector } from "../components"
import { UpdateUserRoleGroupMutationVariables, useDepartmentsQuery, UserRoleGroupFragment, UserRoleTypes, useUpdateUserRoleGroupMutation } from "../graphql"

type UpdateUserRoleGroupFormValues = UpdateUserRoleGroupMutationVariables["input"]

const validationSchema = yup.object<Record<keyof UpdateUserRoleGroupFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	allowedZoneGroupIds: yup.array(yup.string()).label("Allowed Zone Groupș"),
	allowedZoneIds: yup.array(yup.string()).label("Allowed Zones"),
	departmentId: yup.string().required().label("Deparment"),
})

export type UpdateUserRoleGroupFormProps = {
	userRoleGroup: UserRoleGroupFragment
}

export const UpdateUserRoleGroupForm: React.FC<UpdateUserRoleGroupFormProps> = ({ userRoleGroup }) => {
	const initialValues: UpdateUserRoleGroupFormValues = {
		label: { name: userRoleGroup.label.name, description: userRoleGroup.label.description },
		allowedZoneGroupIds: userRoleGroup.allowedZoneGroupIds,
		allowedZoneIds: userRoleGroup.allowedZoneIds,
		departmentId: userRoleGroup.departmentId,
	}

	const [{ fetching }, updateUserRoleGroup] = useUpdateUserRoleGroupMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async ({ departmentId, label, allowedZoneGroupIds, allowedZoneIds }: UpdateUserRoleGroupFormValues) => {
		const { data, error } = await updateUserRoleGroup({
			userRoleGroupId: userRoleGroup._id,
			input: { departmentId, label, allowedZoneGroupIds, allowedZoneIds },
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.updateUserRoleGroup) {
			navigate(`/userRoleGroups/${data.updateUserRoleGroup._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<UpdateUserRoleGroupFormValues>({ initialValues, validationSchema, onSubmit })

	const [{ data: departmentsData, error: departmentsError, fetching: departmentsFetching }] = useDepartmentsQuery()

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch" maxW="xl">
				<FormControl>
					<FormLabel fontWeight="bold">Type</FormLabel>

					<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select type" _placeholder={{ color: "grayscale.placeholer" }} value={userRoleGroup.type} isDisabled>
						{Object.values(UserRoleTypes)
							.filter((r) => r !== UserRoleTypes.Admin)
							.map((type) => (
								<option key={type} style={{ backgroundColor: "transparent" }} value={type}>
									{startCase(type)}
								</option>
							))}
					</Select>

					<FormErrorMessage>{formik.errors.departmentId}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.departmentId && formik.errors.departmentId)}>
					<FormLabel fontWeight="bold">Department</FormLabel>

					{departmentsFetching ? (
						<Text>Fetching departments</Text>
					) : departmentsError ? (
						<VStack>
							<Text>Couldn&apos;t fetch departments</Text>
							<Text>{departmentsError.message.replace("[GraphQL] ", "")}</Text>
						</VStack>
					) : !departmentsData?.departments.length ? (
						<VStack>
							<Text>Couldn&apos;t fetch departments</Text>
						</VStack>
					) : (
						<Select resize="vertical" variant="filled" bgColor="grayscale.input-background" placeholder="Select department" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("departmentId")}>
							{departmentsData.departments.map((department) => (
								<option key={department._id} style={{ backgroundColor: "transparent" }} value={department._id}>
									{department.label.name}
								</option>
							))}
						</Select>
					)}

					<FormErrorMessage>{formik.errors.departmentId}</FormErrorMessage>
				</FormControl>

				<Tabs
					size="sm"
					variant="soft-rounded"
					colorScheme="primary"
					isLazy
					lazyBehavior="unmount"
					onChange={(index) => {
						if (index === 1) {
							formik.setFieldValue("allowedZoneIds", [])
						} else {
							formik.setFieldValue("allowedZoneGroupIds", [])
						}
					}}
				>
					<TabList w="fit-content" bgColor="primary.50" p={1} rounded="2xl">
						<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
							Allowed Zones
						</Tab>
						<Tab _selected={{ bgColor: "primary.200", color: "white" }} rounded="xl">
							Allowed Zone Groups
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel px="0">
							<FormControl isInvalid={Boolean(formik.touched.allowedZoneIds && formik.errors.allowedZoneIds)}>
								<ZoneSelector value={formik.values.allowedZoneIds || []} onUpdate={(zoneIds) => formik.setFieldValue("allowedZoneIds", zoneIds)} />

								<FormErrorMessage>{formik.errors.allowedZoneIds}</FormErrorMessage>
							</FormControl>
						</TabPanel>
						<TabPanel px="0">
							<FormControl isInvalid={Boolean(formik.touched.allowedZoneGroupIds && formik.errors.allowedZoneGroupIds)}>
								<ZoneGroupsSelector value={formik.values.allowedZoneGroupIds || []} onUpdate={(zoneGroupIds) => formik.setFieldValue("allowedZoneGroupIds", zoneGroupIds)} />

								<FormErrorMessage>{formik.errors.allowedZoneIds}</FormErrorMessage>
							</FormControl>
						</TabPanel>
					</TabPanels>
				</Tabs>
				<Button type="submit" colorScheme="primary" isLoading={fetching}>
					Update
				</Button>
			</VStack>
		</VStack>
	)
}
