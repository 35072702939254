import { Center, Flex, Icon, Link, Spinner, Tag, Text, VStack } from "@chakra-ui/react"
import { differenceInSeconds } from "date-fns"
import React, { useEffect, useState } from "react"
import { XCircle } from "react-feather"
import { NavLink } from "react-router-dom"
import { UserLocationRecordStatus, UserLocationTransitionFragment, useUserCurrentLocationRecordQuery, useUserLocationRecordUpdateSubscription, useZoneByIdQuery } from "../../graphql"
import { getDurationInWords, transformDateTime } from "../../utils"
import { Card, Stat } from "../common"
import { UserCurrentPresence } from "./UserCurrentPresence"

export type UserCurrentLocationProps = {
	userId: string
}

export const UserCurrentLocation: React.FC<UserCurrentLocationProps> = ({ userId }) => {
	const [{ data, fetching, error }] = useUserCurrentLocationRecordQuery({ variables: { userId } })
	useUserLocationRecordUpdateSubscription({ variables: { userId } })

	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({ variables: { zoneId: data?.userCurrentLocationRecord?.zoneId ?? "" }, pause: !data?.userCurrentLocationRecord?.zoneId })

	const [mode, setMode] = useState("location")

	useEffect(() => {
		if (!data?.userCurrentLocationRecord && !fetching && !error && mode !== "presence") {
			setMode("presence")
		} else if (!!data?.userCurrentLocationRecord && mode !== "location") {
			setMode("location")
		}
	}, [data, fetching, error, mode])

	const [lastTransition, setLastTransition] = useState<UserLocationTransitionFragment | null>()

	const [{ data: tZoneData }] = useZoneByIdQuery({ variables: { zoneId: lastTransition?.zoneId ?? "" }, pause: !lastTransition?.zoneId })

	useEffect(() => {
		let interval: NodeJS.Timeout | null = null

		if (data?.userCurrentLocationRecord?.transitions && data.userCurrentLocationRecord.transitions.length > 0) {
			if (interval) {
				clearInterval(interval)
			}

			const transition = data.userCurrentLocationRecord.transitions[data.userCurrentLocationRecord.transitions.length - 1]
			const seconds = differenceInSeconds(new Date(), new Date(transition.createdAt))

			if (lastTransition?.zoneId !== transition.zoneId && seconds < 10) setLastTransition(transition)

			interval = setInterval(() => {
				const seconds = differenceInSeconds(new Date(), new Date(transition.createdAt))

				if (seconds > 10) {
					setLastTransition(null)
				}
			}, 1000)
		}

		return () => {
			if (interval) {
				clearInterval(interval)
			}
		}
	}, [data?.userCurrentLocationRecord?.transitions, lastTransition])

	if (mode === "presence") return <UserCurrentPresence userId={userId} />

	return (
		<Card title="Current Location">
			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading current location</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.userCurrentLocationRecord && data.userCurrentLocationRecord.status !== UserLocationRecordStatus.Final ? (
				<VStack w="full" align="flex-start" p={4}>
					{data.userCurrentLocationRecord.isRestricted && (
						<Tag colorScheme="error" variant="solid">
							Restricted <Icon as={XCircle} ml="2" />
						</Tag>
					)}
					{lastTransition && tZoneData?.zoneById && lastTransition.zoneId !== data.userCurrentLocationRecord.zoneId && (
						<Tag colorScheme="primary" variant="subtle" bgColor="primary.50">
							<Link as={NavLink} to={`/zones/${lastTransition.zoneId}`}>
								Close to {tZoneData.zoneById.label.name}
							</Link>
						</Tag>
					)}
					<Flex align="flex-start" flexWrap="wrap" gap={4}>
						<Stat
							label="Zone"
							value={
								fetchingZone ? (
									<Spinner size="sm" />
								) : zoneError ? (
									<Text color="error.500">{zoneError.message.replace("[GraphQL] ", "")}</Text>
								) : !zoneData?.zoneById ? (
									<Text color="error.500">No zone</Text>
								) : (
									<Link as={NavLink} to={`/zones/${data.userCurrentLocationRecord.zoneId}`}>
										{zoneData.zoneById.label.name}
									</Link>
								)
							}
						/>
						<Stat label="Since" value={transformDateTime(new Date(data.userCurrentLocationRecord.startAt))} />
						{data.userCurrentLocationRecord.status !== UserLocationRecordStatus.Active && <Stat label="Until" value={transformDateTime(new Date(data.userCurrentLocationRecord.endAt))} />}
						<Stat label="Status" value={data.userCurrentLocationRecord.status} />
						<Stat
							label="Duration"
							value={getDurationInWords(
								differenceInSeconds(data.userCurrentLocationRecord.status === UserLocationRecordStatus.Active ? new Date() : new Date(data.userCurrentLocationRecord.endAt), new Date(data.userCurrentLocationRecord.startAt))
							)}
						/>
					</Flex>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="md" color="error.500">
						Couldn&apos;t find current location
					</Text>
				</Center>
			)}
		</Card>
	)
}
