import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import { format } from "date-fns"
import React from "react"
import { NavLink } from "react-router-dom"
import { DepartmentFragment } from "../../graphql"
import { Stat } from "../common"

export type DepartmentListItemProps = {
	department: DepartmentFragment
}

export const DepartmentListItem: React.FC<DepartmentListItemProps> = ({ department }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/departments/${department._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Name" value={department.label.name} />
				<Stat label="Last updated at" value={format(new Date(department.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(department.createdAt), "MMM dd, yyyy p")} />{" "}
			</Flex>
		</ListItem>
	)
}
