import { Center, chakra, Heading, LinkBox, LinkOverlay, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { AssetLocationRecordStatus, FloorFragment, useAssetLocationRecordsInBuildingQuery, useZonesInBuildingQuery } from "../../graphql"

export type BuildingFloorItemAssetRecordsProps = {
	floor: FloorFragment
	buildingId: string
}

export const BuildingFloorItemAssetRecords: React.FC<BuildingFloorItemAssetRecordsProps> = ({ floor, buildingId }) => {
	const [{ data, fetching, error }] = useAssetLocationRecordsInBuildingQuery({ variables: { buildingId } })

	const [{ data: zonesData }] = useZonesInBuildingQuery({ variables: { buildingId } })

	const floorRecords = useMemo(() => {
		if (!data?.assetLocationRecordsInBuilding.length || !zonesData?.zonesInBuilding.length) return []

		return data.assetLocationRecordsInBuilding.filter((r) => {
			const zone = zonesData.zonesInBuilding.find((z) => z._id === r.zoneId)

			return zone?.floorId === floor._id
		})
	}, [JSON.stringify(data), JSON.stringify(zonesData?.zonesInBuilding)])

	return (
		<VStack
			maxW="3xs"
			as={LinkBox}
			align="flex-start"
			px="6"
			py="4"
			m="1"
			bgColor="grayscale.background"
			transition="background-color, border-color .1s ease-in"
			borderWidth="4px"
			borderStyle="solid"
			borderColor={floorRecords.length ? "green.200" : "red.200"}
			_hover={{ bgColor: "primary.50", borderColor: "primary.200" }}
			rounded="xl"
			cursor="pointer"
			justify="space-between"
		>
			<LinkOverlay as={NavLink} to={`/zones/buildings/${buildingId}/floors/${floor._id}`} />
			<Heading fontSize="sm" color="grayscale.title">
				{floor.label.name}
			</Heading>

			{fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.assetLocationRecordsInBuilding ? (
				<VStack align="stretch">
					<Heading fontSize="2xl" color={floorRecords.length ? "green.500" : "red.500"}>
						{floorRecords.length || 0}{" "}
						<chakra.span fontSize="sm" fontWeight="normal">
							assets
						</chakra.span>
					</Heading>

					<Text fontSize="sm">
						{floorRecords.some((r) => r.status === AssetLocationRecordStatus.Stale) && (
							<chakra.span color="grayscale.label"> • {floorRecords.filter((r) => r.status === AssetLocationRecordStatus.Stale).length} are stale</chakra.span>
						)}
					</Text>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
