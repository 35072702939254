import { Center, chakra, Heading, HStack, Link, Spinner, Text, VStack } from "@chakra-ui/react"
import React, { Fragment } from "react"
import { NavLink } from "react-router-dom"
import { DashboardTypes } from "../../constants"
import { useActiveUserIdsQuery, useTypeWiseActiveUsersQuery } from "../../graphql"
import { useDashboardContext } from "../../hooks"
import { useQueryRefresher } from "../../hooks/useQueryRefresher"

export const CurrentlyActiveUsers: React.FC = () => {
	const { type } = useDashboardContext()

	const [{ data, fetching, error }, refreshActiveUserIdsQuery] = useActiveUserIdsQuery({ variables: { isRestricted: type === DashboardTypes.CONFIDENTIALITY } })
	const [{ data: typeWiseData }, refreshTypeWiseActiveUserIdsQuery] = useTypeWiseActiveUsersQuery()

	useQueryRefresher(() => refreshActiveUserIdsQuery({ requestPolicy: "network-only" }))
	useQueryRefresher(() => refreshTypeWiseActiveUserIdsQuery({ requestPolicy: "network-only" }))

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">Currently active users</Heading>
			{!data?.activeUserIds && fetching ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading records</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : error ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{error.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : data?.activeUserIds ? (
				<VStack align="stretch">
					<HStack>
						<Link as={NavLink} to="/users/active">
							<Heading color="primary.400">{data.activeUserIds.userIds.length || 0}</Heading>
						</Link>
					</HStack>
					<Text fontSize="sm" color="grayscale.label">
						{typeWiseData?.typeWiseActiveUsers
							.sort((a, b) => a.label.name.charCodeAt(0) - b.label.name.charCodeAt(0))
							.map(({ typeId, label, userIds }, i, _) => (
								<Fragment key={typeId}>
									<Link key={typeId} as={NavLink} to={`/users/active?userType=${typeId}`}>
										<chakra.span fontWeight="semibold">
											{userIds.length || 0} <chakra.span fontWeight="normal">{label.name}</chakra.span>
										</chakra.span>
									</Link>
									{_.length - 1 !== i && <chakra.span fontWeight="semibold"> • </chakra.span>}
								</Fragment>
							))}
					</Text>
				</VStack>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any records.
					</Text>
				</Center>
			)}
		</VStack>
	)
}
