/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Textarea, useToast, VStack } from "@chakra-ui/react"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { ResolveAlertMutationVariables, useResolveAlertMutation } from "../graphql"

type ResolveAlertFormValues = Omit<ResolveAlertMutationVariables, "alertId">

const validationSchema = yup.object<Record<keyof ResolveAlertFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	remarks: yup.string().required().label("Remarks"),
})

const initialValues: ResolveAlertFormValues = {
	remarks: "",
}

type ResolveAlertFormProps = {
	alertId: string
}

export const ResolveAlertForm: React.FC<ResolveAlertFormProps> = ({ alertId }) => {
	const [{ fetching }, createAlertFlow] = useResolveAlertMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: ResolveAlertFormValues) => {
		const { data, error } = await createAlertFlow({
			alertId,
			remarks: values.remarks,
		})

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.resolveAlert) {
			navigate(`/alerts/${alertId}`, { replace: true })

			return
		}
	}

	const formik = useFormik<ResolveAlertFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch" maxW="xl">
				<FormControl isInvalid={Boolean(formik.touched.remarks && formik.errors.remarks)} isRequired>
					<FormLabel fontWeight="bold">Remarks</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter remarks" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("remarks")} />

					<FormErrorMessage>{formik.errors.remarks}</FormErrorMessage>
				</FormControl>

				<Button type="submit" colorScheme="primary" isLoading={fetching}>
					Resolve
				</Button>
			</VStack>
		</VStack>
	)
}
