import { Heading, Link, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { AlertTypes, SosRecordStatus, useActiveSosRecordIdsQuery, useNewAlertSubscription, useSosRecordsUpdateSubscription } from "../../graphql"
import { useQueryRefresher } from "../../hooks/useQueryRefresher"

export const ActiveSosRecords: React.FC = () => {
	const [sosRecords, setSosRecords] = useState<string[]>([])

	const [{ data }, refresh] = useActiveSosRecordIdsQuery()

	const [{ data: alertData }] = useNewAlertSubscription()
	const [{ data: sosRecordsData }] = useSosRecordsUpdateSubscription({ variables: { recordIds: sosRecords.map((recordId) => recordId) }, pause: !sosRecords.length })

	useQueryRefresher(() => refresh({ requestPolicy: "network-only" }))

	useEffect(() => {
		if (data?.activeSosRecordIds.length) {
			data.activeSosRecordIds.forEach((recordId) => {
				setSosRecords((prev) => {
					if (!prev?.includes(recordId)) return [recordId, ...(prev || [])]
					else return prev
				})
			})
		}
	}, [data?.activeSosRecordIds.length])

	useEffect(() => {
		if (alertData?.newAlert.type === AlertTypes.Sos && alertData.newAlert.sosRecordId && !sosRecords.find((recordId) => recordId === alertData.newAlert.sosRecordId)) {
			setSosRecords((prev) => [alertData.newAlert.sosRecordId!, ...(prev || [])])
		}
	}, [alertData])

	useEffect(() => {
		if (sosRecordsData?.sosRecordsUpdate && sosRecordsData.sosRecordsUpdate.status === SosRecordStatus.Final && sosRecords.find((recordId) => recordId === sosRecordsData.sosRecordsUpdate._id)) {
			const _sosRecords = [...sosRecords]
			_sosRecords.splice(
				sosRecords.findIndex((recordId) => recordId === sosRecordsData.sosRecordsUpdate._id),
				1
			)

			setSosRecords(_sosRecords)
		}
	}, [sosRecordsData])

	return (
		<VStack flex="1" maxW="52" align="stretch" spacing={4}>
			<Heading fontSize="sm">Active SOS</Heading>

			<Link as={NavLink} to="/sosRecords/active">
				<Heading color="red.400">{sosRecords.length || 0}</Heading>
			</Link>
		</VStack>
	)
}
