/* eslint-disable @typescript-eslint/indent */
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Popover, PopoverBody, PopoverContent, PopoverTrigger, Text, Textarea, useToast, VStack } from "@chakra-ui/react"
import { format } from "date-fns"
import { useFormik } from "formik"
import React, { FormEvent } from "react"
import { DateRange } from "react-date-range"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import Lazy from "yup/lib/Lazy"
import Reference from "yup/lib/Reference"
import { BuildingSelector } from "../components"
import { GenerateBuildingLocationRecordsReportMutationVariables, useGenerateBuildingLocationRecordsReportMutation } from "../graphql"

type GenerateBuildingLocationRecordsReportFormValues = GenerateBuildingLocationRecordsReportMutationVariables["input"]

const validationSchema = yup.object<Record<keyof GenerateBuildingLocationRecordsReportFormValues, yup.AnySchema<any, any, any> | Reference<unknown> | Lazy<any, any>>>({
	buildingIds: yup.array().of(yup.string()).min(1).required().label("Buildings"),
	label: yup.object({
		name: yup.string().required().label("Name"),
		description: yup.string().label("Description"),
	}),
	sinceDate: yup.date().required().label("Since Date"),
	untilDate: yup.date().required().label("Until Date"),
	buildingsFilter: yup.object({
		keyword: yup.string().nullable(),
		isAllSelected: yup.boolean().nullable(),
	}),
})

const initialValues: GenerateBuildingLocationRecordsReportFormValues = {
	buildingIds: [],
	label: {
		name: "",
		description: "",
	},
	sinceDate: new Date(),
	untilDate: new Date(),
	buildingsFilter: {
		keyword: "",
		isAllSelected: false,
	},
}

export const GenerateBuildingLocationRecordsReportForm: React.FC = () => {
	const [{ fetching }, generateBuildingLocationRecordsReport] = useGenerateBuildingLocationRecordsReportMutation()

	const toast = useToast()
	const navigate = useNavigate()

	const onSubmit = async (values: GenerateBuildingLocationRecordsReportFormValues) => {
		const { data, error } = await generateBuildingLocationRecordsReport({ input: values })

		if (error) {
			return toast({
				description: error.message.replace("[GraphQL] ", ""),
				status: "error",
			})
		}

		if (data?.generateBuildingLocationRecordsReport) {
			navigate(`/reports/past/${data.generateBuildingLocationRecordsReport._id}`, { replace: true })

			return
		}
	}

	const formik = useFormik<GenerateBuildingLocationRecordsReportFormValues>({ initialValues, validationSchema, onSubmit })

	return (
		<VStack as="form" onSubmit={(e) => formik.handleSubmit(e as unknown as FormEvent<HTMLFormElement>)} w="full" align="stretch" spacing={6}>
			<VStack w="full" align="stretch" maxW="xl">
				<FormControl isInvalid={Boolean(formik.touched.label?.name && formik.errors.label?.name)} isRequired>
					<FormLabel fontWeight="bold">Name</FormLabel>

					<Input variant="filled" bgColor="grayscale.input-background" placeholder="Enter name" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.name")} />

					<FormErrorMessage>{formik.errors.label?.name}</FormErrorMessage>
				</FormControl>
				<FormControl isInvalid={Boolean(formik.touched.label?.description && formik.errors.label?.description)}>
					<FormLabel fontWeight="bold">Description</FormLabel>

					<Textarea variant="filled" bgColor="grayscale.input-background" placeholder="Enter description" _placeholder={{ color: "grayscale.placeholer" }} {...formik.getFieldProps("label.description")} />

					<FormErrorMessage>{formik.errors.label?.description}</FormErrorMessage>
				</FormControl>
				<Popover>
					<PopoverTrigger>
						{formik.values.sinceDate && formik.values.untilDate && (
							<FormControl>
								<FormLabel fontWeight="bold">Date Range</FormLabel>

								<Input variant="filled" bgColor="grayscale.input-background" value={`${format(formik.values.sinceDate, "MMM dd, yyyy")} - ${format(formik.values.untilDate, "MMM dd, yyyy")}`} isReadOnly />
							</FormControl>
						)}
					</PopoverTrigger>
					<PopoverContent w="full" _focus={{ shadow: "none" }}>
						<PopoverBody w="full" p="0">
							<DateRange
								editableDateInputs={true}
								onChange={(item) => {
									if (item?.selection.startDate && item?.selection.endDate) {
										formik.setFieldValue("sinceDate", new Date(item.selection.startDate))
										formik.setFieldValue("untilDate", new Date(item.selection.endDate))
									}
								}}
								moveRangeOnFirstSelection={false}
								ranges={[{ startDate: formik.values.sinceDate, endDate: formik.values.untilDate, key: "selection" }]}
								maxDate={new Date()}
							/>
						</PopoverBody>
					</PopoverContent>
				</Popover>
				<FormControl isInvalid={Boolean(formik.touched.buildingIds && formik.errors.buildingIds)}>
					<FormLabel fontWeight="bold">Buildings</FormLabel>

					<BuildingSelector
						value={formik.values.buildingIds}
						onUpdate={(buildingIds, filter) => {
							formik.setFieldValue("buildingIds", buildingIds)
							formik.setFieldValue("buildingsFilter", filter)
						}}
					/>
					<Text fontSize="sm" color="error.400">
						{formik.errors.buildingIds}
					</Text>
				</FormControl>
				<Button type="submit" colorScheme="primary" isLoading={fetching}>
					Generate
				</Button>
			</VStack>
		</VStack>
	)
}
