import { Divider, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import React, { PropsWithChildren } from "react"

export type HeaderProps = {
	title: string
	subHeading?: string | React.ReactNode
}

export const Header: React.FC<HeaderProps & PropsWithChildren> = ({ title, subHeading, children }) => {
	return (
		<VStack position="sticky" top={0} bgColor="rgba(255, 255, 255, 0.4)" backdropFilter="blur(8px)" zIndex={999} w="full" spacing={0} align="stretch">
			<HStack flexWrap="wrap" w="full" alignItems="center" justifyContent="space-between" px={4} minH="12">
				<HStack>
					<VStack align="flex-start" spacing={0}>
						<Heading fontSize="md" color="grayscale.title" lineHeight="100%">
							{title}
						</Heading>
						{subHeading &&
							(typeof subHeading === "string" ? (
								<Text fontSize="xs" color="grayscale.label">
									{subHeading}
								</Text>
							) : (
								subHeading
							))}
					</VStack>
				</HStack>
				<HStack>{children}</HStack>
			</HStack>
			<Divider m="0 !important" />
		</VStack>
	)
}
