import { Button, Flex, HStack, Icon, Link, ListItem, Spinner, Text, VStack } from "@chakra-ui/react"
import differenceInSeconds from "date-fns/differenceInSeconds"
import format from "date-fns/format"
import React from "react"
import { ArrowRight, Check, X } from "react-feather"
import { NavLink } from "react-router-dom"
import { SosRecordFragment, useMeQuery, useUserByIdQuery, useUserLatestLocationRecordQuery, useZoneByIdQuery } from "../../graphql"
import { getDurationInWords } from "../../utils"
import { Stat, UserTag } from "../common"

export type SosRecordListItemProps = {
	sosRecord: SosRecordFragment
}

export const SosRecordListItem: React.FC<SosRecordListItemProps> = ({ sosRecord }) => {
	const [{ data: userData, fetching: fetchingUser, error: userError }] = useUserByIdQuery({ variables: { userId: sosRecord.userId } })
	const [{ data: userLocationData, fetching: fetchingUserLocation, error: userLocationError }] = useUserLatestLocationRecordQuery({ variables: { userId: sosRecord.userId } })
	const [{ data: zoneData, fetching: fetchingZone, error: zoneError }] = useZoneByIdQuery({ variables: { zoneId: userLocationData?.userLatestLocationRecord?.zoneId || "" }, pause: !userLocationData?.userLatestLocationRecord?.zoneId })

	const [{ data: meData }] = useMeQuery()

	return (
		<ListItem>
			<VStack w="full" align="stretch" rounded="xl">
				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					<Stat
						label="User"
						value={
							fetchingUser ? (
								<Spinner size="sm" />
							) : userError ? (
								<Text color="error.500">{userError.message.replace("[GraphQL] ", "")}</Text>
							) : !userData?.userById ? (
								<Text color="error.500">No user</Text>
							) : (
								<Link as={NavLink} to={`/users/${sosRecord.userId}`}>
									<UserTag user={userData.userById} />
								</Link>
							)
						}
					/>
					<Stat
						label="Zone"
						value={
							fetchingUserLocation || fetchingZone ? (
								<Spinner size="sm" />
							) : userLocationError || zoneError ? (
								<Text color="error.500">{(userLocationError || zoneError)!.message.replace("[GraphQL] ", "")}</Text>
							) : !userLocationData?.userLatestLocationRecord || !zoneData?.zoneById ? (
								<Text color="error.500">Not found</Text>
							) : (
								<Link as={NavLink} to={`/zones/${userLocationData.userLatestLocationRecord.zoneId}`}>
									{zoneData.zoneById.label.name}
								</Link>
							)
						}
					/>
					{userLocationData?.userLatestLocationRecord && <Stat label="Last seen" value={`${getDurationInWords(differenceInSeconds(new Date(), new Date(userLocationData.userLatestLocationRecord.endAt)))} ago`} />}
					<Stat label="Since" value={format(new Date(sosRecord.startAt), "MMM dd, yyyy p")} />
					<Stat label="Until" value={format(new Date(sosRecord.endAt), "MMM dd, yyyy p")} />
					<Stat label="Duration" value={getDurationInWords(differenceInSeconds(new Date(sosRecord.endAt), new Date(sosRecord.startAt)))} />
					<Stat
						label="Resolution"
						value={
							sosRecord.isAlertResolved ? (
								<VStack align="flex-start" spacing={0}>
									<HStack>
										<Icon as={Check} color="green.500" />
										<Text color="green.500">Resolved</Text>
									</HStack>
									{sosRecord.alertId && (
										<Button variant="link" size="sm" rightIcon={<Icon as={ArrowRight} />} as={NavLink} to={`/alerts/${sosRecord.alertId}`}>
											See resolution
										</Button>
									)}
								</VStack>
							) : (
								<VStack align="flex-start" spacing={0}>
									<HStack>
										<Icon as={X} color="red.500" />
										<Text color="red.500">Not resolved</Text>
									</HStack>
									{sosRecord.alertId && meData?.me?._id && sosRecord.allowedToResolveUserIds?.includes(meData.me._id) && (
										<Button variant="link" size="sm" rightIcon={<Icon as={ArrowRight} />} as={NavLink} to={`/alerts/${sosRecord.alertId}/resolve`}>
											Resolve now
										</Button>
									)}
								</VStack>
							)
						}
					/>
				</Flex>
			</VStack>
		</ListItem>
	)
}
