import { Button, ButtonGroup, Center, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import format from "date-fns/format"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { PermissionTags, TagStatus, useMeQuery, UserFragment, UserRoleTypes, useTagByIdQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type UserTagDetailsProps = {
	user: UserFragment
	disableActions?: boolean
}

export const UserTagDetails: React.FC<UserTagDetailsProps> = ({ user, disableActions }) => {
	const [{ data: meData }] = useMeQuery()
	const [{ data: tagData, fetching: fetchingTag, error: tagError }] = useTagByIdQuery({ variables: { tagId: user.tagId ?? "" }, pause: !user.tagId })

	const { checkForPermissions } = useUserPermissions()

	const canAssign = useMemo(() => {
		if (user.tagId) return false

		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.AssignTagToWorkingUser)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.AssignTagToShortTermUser)[0]
		}
	}, [JSON.stringify(user), meData?.me?._id])

	const canFlush = useMemo(() => {
		if (!user.tagId) return false

		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.FlushTagFromWorkingUser)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.FlushTagFromShortTermUser)[0]
		}
	}, [meData?.me?._id, JSON.stringify(user)])

	const canDeactivate = useMemo(() => {
		if (!user.tagId || tagData?.tagById?.status !== TagStatus.Active) return false

		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.DeactivateWorkingUserTag)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.DeactivateShortTermUserTag)[0]
		}
	}, [meData?.me?._id, JSON.stringify(user), JSON.stringify(tagData?.tagById)])

	const canActivate = useMemo(() => {
		if (!user.tagId || tagData?.tagById?.status !== TagStatus.Inactive) return false

		if (user.roleType === UserRoleTypes.Admin) return false

		if (user._id === meData?.me?._id) return false

		if (user.roleType === UserRoleTypes.Working) {
			return checkForPermissions(PermissionTags.ActivateWorkingUserTag)[0]
		}

		if (user.roleType === UserRoleTypes.ShortTerm) {
			return checkForPermissions(PermissionTags.ActivateShortTermUserTag)[0]
		}
	}, [meData?.me?._id, JSON.stringify(user), JSON.stringify(tagData?.tagById)])

	return (
		<Card
			title="Tag"
			action={
				disableActions ? (
					<></>
				) : (
					<ButtonGroup>
						{canAssign && (
							<Button as={NavLink} to={`/users/${user._id}/assignTag`} colorScheme="primary" size="sm">
								Assign
							</Button>
						)}
						{canFlush && (
							<Button as={NavLink} to={`/users/${user._id}/flushTag`} colorScheme="error" size="sm">
								Flush
							</Button>
						)}
						{canDeactivate && (
							<Button as={NavLink} to={`/users/${user._id}/deactivateTag`} colorScheme="error" size="sm">
								Deactivate
							</Button>
						)}
						{canActivate && (
							<Button as={NavLink} to={`/users/${user._id}/activateTag`} colorScheme="success" size="sm">
								Activate
							</Button>
						)}
					</ButtonGroup>
				)
			}
		>
			{fetchingTag && !tagData?.tagById ? (
				<Center w="full" py="4">
					<VStack w="full" color="grayscale.label">
						<Text fontSize="sm">Loading tag</Text>
						<Spinner size="sm" />
					</VStack>
				</Center>
			) : tagError ? (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						{tagError.message.replace("[GraphQL] ", "")}
					</Text>
				</Center>
			) : tagData?.tagById ? (
				<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
					<Stat label="UID" value={tagData.tagById.tagDecimalId || "Not available"} />
					<Stat label="UID (HEX)" value={tagData.tagById.tagHexId || "Not available"} />
					<Stat
						label="Status"
						value={tagData.tagById.status === TagStatus.Active ? <Text color="success.600">Active</Text> : tagData.tagById.status === TagStatus.Inactive ? <Text color="error.600">Inactive</Text> : "Not available"}
					/>
					<Stat label="Battery Status" value={tagData.tagById.batteryStatus || "Not available"} />
					<Stat label="Assigned Since" value={tagData.tagById.assignedAt ? format(new Date(tagData.tagById.assignedAt), "MMM dd, yyyy p") : "Not available"} />
				</Flex>
			) : (
				<Center py="4">
					<Text fontSize="sm" fontWeight="semibold" color="error.500">
						Couldn&apos;t find any tag
					</Text>
				</Center>
			)}
		</Card>
	)
}
