import { Flex, LinkBox, LinkOverlay, ListItem } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React from "react"
import { NavLink } from "react-router-dom"
import { AlertFragment } from "../../graphql"
import { Stat } from "../common"

export type AlertListItemProps = {
	alert: AlertFragment
}

export const AlertListItem: React.FC<AlertListItemProps> = ({ alert }) => {
	return (
		<ListItem as={LinkBox} _hover={{ bgColor: "blackAlpha.100" }} transition="background-color .1s ease-in" rounded="xl" cursor="pointer">
			<LinkOverlay as={NavLink} to={`/alerts/${alert._id}`} />
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Type" value={startCase(alert.type)} />
				<Stat label="Resolution" value={alert.isResolutionRequired ? `Required ${alert.resolution ? "and resolved" : "but not resolved"}` : "Not required"} />
				<Stat label="Last updated at" value={format(new Date(alert.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(alert.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</ListItem>
	)
}
