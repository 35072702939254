import { Button, Flex, HStack, Link, Spinner, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import startCase from "lodash/startCase"
import React, { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { AlertFlowFragment, PermissionTags, useAlertTargetGroupsOfAlertFlowQuery } from "../../graphql"
import { useUserPermissions } from "../../hooks"
import { Card, Stat } from "../common"

export type AlertFlowDetailsProps = {
	alertFlow: AlertFlowFragment
}

export const AlertFlowDetails: React.FC<AlertFlowDetailsProps> = ({ alertFlow }) => {
	const { checkForPermissions } = useUserPermissions()

	const canUpdate = useMemo(() => checkForPermissions(PermissionTags.UpdateAlertFlow)[0], [])

	const [{ data, fetching, error }] = useAlertTargetGroupsOfAlertFlowQuery({ variables: { alertFlowId: alertFlow._id } })

	return (
		<Card
			title="Details"
			action={
				canUpdate && (
					<Button as={NavLink} to={`/alerts/flows/${alertFlow._id}/update`} colorScheme="primary" size="sm">
						Update
					</Button>
				)
			}
		>
			<Flex align="flex-start" flexWrap="wrap" p={4} gap={4}>
				<Stat label="Resolution" value={alertFlow.isResolutionRequired ? "Required" : "Not required"} />
				<Stat label="Type" value={startCase(alertFlow.type)} />
				{Number.isSafeInteger(alertFlow.lowInventoryThreshold) && <Stat label="Threshold" value={alertFlow.lowInventoryThreshold} />}
				<Stat
					label="Targets"
					value={
						fetching ? (
							<Spinner size="sm" />
						) : error ? (
							<Text color="error.500">{error.message.replace("[GraphQL] ", "")}</Text>
						) : !data?.alertTargetGroupsOfAlertFlow.length ? (
							<Text color="info.500">No targets</Text>
						) : (
							<HStack flexWrap="wrap" justify="center" maxW="xs">
								{data?.alertTargetGroupsOfAlertFlow.map((t, i, arr) => (
									<Link key={t._id} as={NavLink} to={`/alerts/targetGroups/${t._id}`}>
										{t.label.name}
										{i < (arr.length || 0) - 1 && ","}
									</Link>
								))}
							</HStack>
						)
					}
				/>
				<Stat label="Last updated at" value={format(new Date(alertFlow.updatedAt), "MMM dd, yyyy p")} />
				<Stat label="Created at" value={format(new Date(alertFlow.createdAt), "MMM dd, yyyy p")} />
			</Flex>
		</Card>
	)
}
