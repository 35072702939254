import { Heading, Link, VStack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { AlertTypes, useActiveRestrictedEntryIdsQuery, useNewAlertSubscription, useRestrictedUserLocationRecordsUpdateSubscription, UserLocationRecordStatus } from "../../graphql"
import { useQueryRefresher } from "../../hooks/useQueryRefresher"

export const ActiveRestrictedUserLocationRecords: React.FC = () => {
	const [restrictedEntries, setRestrictedEntries] = useState<string[]>([])

	const [{ data }, refresh] = useActiveRestrictedEntryIdsQuery()

	const [{ data: alertData }] = useNewAlertSubscription()
	const [{ data: restrictedEntriesData }] = useRestrictedUserLocationRecordsUpdateSubscription({ variables: { recordIds: restrictedEntries.map((recordId) => recordId) }, pause: !restrictedEntries.length })

	useQueryRefresher(() => refresh({ requestPolicy: "network-only" }))

	useEffect(() => {
		if (data?.activeRestrictedEntryIds.recordIds.length) {
			data.activeRestrictedEntryIds.recordIds.forEach((recordId) => {
				if (!restrictedEntries.includes(recordId)) {
					restrictedEntries.push(recordId)
				}
			})
		}
	}, [data])

	useEffect(() => {
		if (alertData?.newAlert.type === AlertTypes.RestrictedEntry && alertData.newAlert.userLocationRecordId && !restrictedEntries.find((recordId) => recordId === alertData.newAlert.userLocationRecordId)) {
			setRestrictedEntries((prev) => [alertData.newAlert.userLocationRecordId!, ...(prev || [])])
		}
	}, [alertData])

	useEffect(() => {
		if (
			restrictedEntriesData?.restrictedUserLocationRecordsUpdate &&
			(!restrictedEntriesData.restrictedUserLocationRecordsUpdate.isRestricted || restrictedEntriesData.restrictedUserLocationRecordsUpdate.status === UserLocationRecordStatus.Final) &&
			restrictedEntries.find((recordId) => recordId === restrictedEntriesData.restrictedUserLocationRecordsUpdate._id)
		) {
			const _restrictedEntries = [...restrictedEntries]
			_restrictedEntries.splice(
				restrictedEntries.findIndex((recordId) => recordId === restrictedEntriesData.restrictedUserLocationRecordsUpdate._id),
				1
			)

			setRestrictedEntries(_restrictedEntries)
		}
	}, [restrictedEntriesData])

	return (
		<VStack flex="1" align="stretch" spacing={4}>
			<Heading fontSize="sm">Restricted entries</Heading>

			<Link as={NavLink} to="/restrictedEntries/active">
				<Heading color="red.400">{restrictedEntries.length || 0}</Heading>
			</Link>
		</VStack>
	)
}
