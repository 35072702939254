import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef, Fragment } from "react"
import { useInViewport } from "react-in-viewport"
import { UserGroupFragment } from "../../graphql"
import { UserGroupListItem } from "./UserGroupListItem"

export type UserGroupsListProps = {
	userGroups: UserGroupFragment[]
	loadMore?: () => void
}

export const UserGroupsList: React.FC<UserGroupsListProps> = ({ userGroups, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{userGroups.map((userGroup, i, arr) => (
				<Fragment key={userGroup._id}>
					<UserGroupListItem key={userGroup._id} userGroup={userGroup} />
					{i < arr.length - 1 && <Divider />}
				</Fragment>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
