import { Box, Divider, List } from "@chakra-ui/react"
import React, { useEffect, useRef } from "react"
import { useInViewport } from "react-in-viewport"
import { ZoneGroupFragment } from "../../graphql"
import { ZoneGroupListItem } from "./ZoneGroupListItem"

export type ZoneGroupsListProps = {
	zoneGroups: ZoneGroupFragment[]
	loadMore?: () => void
}

export const ZoneGroupsList: React.FC<ZoneGroupsListProps> = ({ zoneGroups, loadMore }) => {
	const ref = useRef()

	const { inViewport } = useInViewport(ref as any, { threshold: 0.25 })

	useEffect(() => {
		if (inViewport) {
			loadMore?.()
		}
	}, [inViewport, loadMore])

	return (
		<List spacing={2}>
			{zoneGroups.map((zoneGroup, i, arr) => (
				<>
					<ZoneGroupListItem key={zoneGroup._id} zoneGroup={zoneGroup} />
					{i < arr.length - 1 && <Divider />}
				</>
			))}
			<Box w="full" h="2" ref={ref as any} />
		</List>
	)
}
